.menu-categorias {
  background-color: $color-background;
  // position: relative;
  // z-index: 999;
  &__container {
  }
}

.home .menu-categorias {
  // box-shadow: 0px 30px 30px rgba($color: #000000, $alpha: 0.2);
  position: relative;
  z-index: 3;
}

.ubermenu.ubermenu-desktop-view {
  z-index: 999 !important;
  .ubermenu-nav {
    font-size: 1rem !important;
    border-top: 1px solid $color-grey-5 !important;
    border-bottom: 1px solid $color-grey-5 !important;
  }

  .ubermenu-nav > li.ubermenu-item > a {
    color: $color-text-body;
    font-weight: $font-weight-normal;
    font-family: $typo-secundaria;
    font-size: $font-size-small;
    padding: 10px 15px;
    &:hover {
      background-color: darken($color-primary, 10%);
      color: $color-white;
    }
    @media screen and (max-width: 1440px) {
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  .ubermenu-nav > li.ubermenu-item-has-children > a {
    padding-right: 24px;
    @media screen and (max-width: 1440px) {
      padding-right: 22px;
    }
  }

  .ubermenu-submenu {
    background-color: #fff !important;
    border: 1px solid rgba($color: #000000, $alpha: 0.1) !important;
    > li > a.ubermenu-target {
      color: $color-gris;
      padding: 10px 20px;
      font-size: $font-size-small;

      &:hover {
        background-color: darken($color-primary, 10%);
        color: $color-background;
      }
    }

    .ubermenu-item.ubermenu-has-submenu-drop {
      & > .ubermenu-target {
        padding-right: 30px;
        .ubermenu-sub-indicator {
          top: 53%;
        }
      }
    }
  }

  &.ubermenu-sub-indicators
    .ubermenu-has-submenu-drop
    > .ubermenu-target
    > .ubermenu-sub-indicator {
    margin-top: -8px;
    font-size: $font-size-small;
  }
}

.ubermenu-responsive-toggle {
  color: $color-black;
  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 0 !important;
  i.fas {
    font-size: 1.4rem;
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }
}

.ubermenu:not(.ubermenu-desktop-view) {
  ul.ubermenu-nav {
    li.ubermenu-item {
      .ubermenu-sub-indicator,
      .ubermenu-sub-indicator-close {
        right: $font-size !important;
        width: 28px;
        text-align: center;
      }
      & > .ubermenu-target {
        padding-left: $font-size;
        padding-right: $space-2;
        .ubermenu-target-title {
          color: $color-dark;
          font-size: $font-size-small;
        }
      }
      & > .ubermenu-submenu {
        background: $color-grey-6;
      }
      &.ubermenu-active {
        background: $color-grey-5;
      }
    }
  }
  .ubermenu-mobile-footer {
    background-color: $color-grey-6;
    .ubermenu-mobile-close-button {
      color: $color-primary;
    }
  }
}

.ubermenu-main.ubermenu-mobile-modal.ubermenu-mobile-view{
  height: 100vh;
  max-height: 100vh !important;
  flex-direction: column-reverse !important;
  .ubermenu-mobile-footer .ubermenu-mobile-close-button{
    justify-content: flex-end;
    i{
      font-size: 1.25rem;
      line-height: 1;
    }
  }
}



.categorias-nav {
  padding: $space-1 0 $space-3 0;

  &__items {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 1fr;
    gap: $space-1 calc($font-size);
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(6, 1fr);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: calc($font-size * 0.5);
  }

  &__img {
    background-color: #f5f5f5;
    border-radius: $border-radius-min;
    img {
      aspect-ratio: 3 / 2;
      width: 100%;
      height: auto;
      object-fit: contain;
      border-radius: $border-radius-min;
      mix-blend-mode: multiply;
    }
  }

  span {
    font-size: $font-size-smaller;
    font-weight: $font-weight-medium;
    color: $color-dark;
  }
}

.nav-footer-legal {
  margin-top: 0.25rem;
  @media screen and (max-width: 575px) {
    margin-top: $font-size;
  }
  &__items {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    @media screen and (max-width: 575px) {
      justify-content: center;
      text-align: center;
    }
    & > li {
      & > a {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        border-right: 1px solid #000000;
      }
      &:first-child {
        & > a {
          padding-left: 0;
        }
      }
      &:last-child {
        & > a {
          border-right: none;
          padding-right: 0;
        }
      }
    }
  }
}

#menu-centrosoporte {
  @media screen and (max-width: 991px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: calc($font-size * 0.5) $font-size;
    li a {
      @include input-buttons;
      padding: 6px 14px;
      font-size: $font-size;
      background-color: $color-primary;
      color: $color-white;
      &:hover {
        background-color: darken($color-primary, 10%);
        color: $color-background;
      }
      @media (max-width: 575px) {
        padding: 6px 14px;
        font-size: $font-size-small;
      }
      @media (max-width: 390px) {
        padding: 6px 14px;
        font-size: $font-size-small;
      }
    }
  }
}

textarea,
select,
input,
.qty {
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: $font-size;
  color: $color-gris;
  @media (max-width: 575px) {
    font-size: $font-size-min;
    padding: 11px 11px;
  }
}

select {
  box-shadow: none;  
}

.qty {
  text-align: center;
  width: 80px;
}

.shop_table .qty {
  width: 70px;
}

select:focus,
select:active,
select {

  background: #fff url('../assets/ico/ico-arrow-down.svg') no-repeat right center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* Hide default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition: $trans;

  &:hover {
    color: $color-primary-dark;
    transition: $trans;
  }
}



.orderby {
  @media (max-width: 575px) {
    display: none;
  }
}



.select2-selection {
  height: auto !important;

  &__rendered {
    padding: 10px 2rem 10px 1rem !important;
    display: block;
  }

  &__arrow {
    padding: 10px 12px;
    margin-top: 12px;
    margin-right: 10px;
    b {
      border-width: 6px 6px 0 6px !important;
    }
  }

  &__clear {
    font-size: 1.5rem !important;
    color: $color-primary;
  }

}

.select2-container--default .select2-results>.select2-results__options {
  @media (max-width: 767px) {
    max-height: 400px !important;
  }
}


.select2-results__option--highlighted {
  background-color: $color-primary !important;
}


.qib-container {
  .minus {
    border-radius: 4px 0 0 4px !important;
  }
  .plus {
    border-radius: 0 4px 4px 0 !important;
  }
}
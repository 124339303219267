$color-primary-lighter: #62d38a70;
$color-primary-light: #62d38a;
$color-primary: #00983a;
$color-primary-dark: darken($color-primary, 10%);
$color-primary-accent: #62d38a;

// $color-primary-lighter: #d3626270;
// $color-primary-light: #d36262;
// $color-primary: #ff0000;
// $color-primary-dark: darken($color-primary, 10%);
// $color-primary-accent: #d36262;

$color-secondary: #f2b809;
$color-secondary-dark: darken($color-secondary, 10%);

$color-accent: rgb(44, 36, 206);
$color-accent-dark: rgb(37, 30, 174);

$color-promo-rosa: #fd76b8;
$color-promo-verde: #77e6b1;
$color-promo-amarillo: #dbecb5;
$color-promo-naranja: #fcb895;
$color-promo-azul: #6eb1db;

$color-hue-green-light: #d9ffef;
$color-hue-green-dark: #165528;

$color-hue-yellow-light: #f4f8db;
$color-hue-yellow-dark: #554716;

$color-hue-blue-light: #dbf1f8;
$color-hue-blue-dark: #162055;

$color-hue-orange-light: #f8ecdb;
$color-hue-orange-dark: #551616;

$color-hue-purple-light: #bd6ffd80;
$color-hue-purple-dark: #ae44ae;

$color-hue-blue2-light: #6edbcd70;
$color-hue-blue2-dark: #479086;

$color-dark: #1e0028;

$color-whatsapp-dark: #128c7e;
$color-whatsapp-light: #25d366;

$color-text-body: #747474;

$color-gris-bg: #f3f8ed;
$color-gris-bg-dark: #2d2d2d;

$color-gris-lighter: rgba(222, 222, 222, 0.557);
$color-gris-light: #dedede;
$color-gris: #747474;
$color-gris-dark: rgb(53, 51, 52);
$color-gris-brown: #352c22;

$color-grey-1: #262626;
$color-grey-2: #424142;
$color-grey-3: #7f7f7f;
$color-grey-4: #b6b6b6;
$color-grey-5: #d7d7d7;
$color-grey-6: #f5f5f5;
$color-grey-6-trans: #f5f5f500;

$color-white: #fff;
$color-black: #000;

$color-rattings: #ff9900;

$color-background: rgba(255, 255, 255, 1);
$color-background-footer: $color-grey-6;

$color-red: rgb(179, 16, 16);

$color-text-footer: $color-dark;

$font-size: 1rem;
$font-size-min: 0.85rem;
$font-size-smaller: 0.8rem;
$font-size-small: 0.9rem;
$font-size-big: 1.125rem;
$font-size-bigger: 1.25rem;

$box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 0px 25px rgba(50, 60, 73, 0.2);
$box-shadow-smaller: 0px 0px 10px rgba(0, 0, 0, 0.1);
$box-shadow-small: 0px 0px 15px rgba(0, 0, 0, 0.1);
$box-shadow-large: 0px 16px 48px rgba(30, 0, 40, 0.1);

$border-radius-min: 0.3rem;
$border-radius: 7px;
$border-radius-max: 1.5rem;
$border-radius-cta: 2.5rem;
$border-radius-rounded: 100px;
$border-dashed: 1px dashed rgba(0, 0, 0, 0.5);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$header-1: 3rem;
$header-1-res: 2.5rem;
$header-2: 2.25rem;
$header-2-res: 1.875rem;
$header-3: 1.5rem;
$header-3-res: 1.25rem;
$header-4: 1.25rem;
$header-5: 1rem;
$header-6: 0.87rem;

$display-1: 6rem;
$display-2: 4.35rem;
$display-3: 3.45rem;
$display-4: 2.18rem;

$space-0: 0px;
$space-05: 0.5rem;
$space-01: 1rem;
$space-1: 1.5rem;
$space-2: 2.5rem; // space-1
$space-3: 4rem;
$space-4: 5rem; // space-2
$space-5: 7.5rem; // space-3

$transition: 0.1s ease-in-out all;
$trans: 0.2s all ease-in-out;
$trans-fade: 0.1s all ease-in-out;
$trans-1: 0.1s all ease-in-out;
$trans-3: 0.3s all ease-in-out;

$typo-principal: "Be Vietnam Pro", sans-serif;
$typo-secundaria: "Be Vietnam Pro", sans-serif;

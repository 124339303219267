.berocket_better_labels.berocket_better_labels_image{
  z-index: 1;
}

.br_alabel > span{
  min-width: max-content !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
  font-family: $typo-principal !important;
  font-size: $font-size-smaller !important;
  b{
    font-weight: $font-weight-normal !important;
  }
}

.wcv-message {
  font-size: $font-size-smaller;
  padding: 2px 10px !important;
  border-radius: 50px !important;
  flex: 0 0 100%;
}
.wcv-message span.icon {
  i{
    font-size: $font-size-small;
  }
}

.wcv-message span.wcvisitor_num {
  font-weight: $font-weight-bold;
}
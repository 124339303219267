h1, h1, h3, h4, h5, h6 {
  font-family: $typo-secundaria;
  font-weight: 900;
}


.e-headers {
  margin-bottom: 2rem;
  &__h3 {
    font-family: $typo-secundaria;
    font-weight: $font-weight-bold;
    font-size: $header-3;
    color: $color-gris-brown;
    font-weight: $font-weight-medium;
    @media (max-width: 575px) {
      font-size: 1.25rem;
    }
  }

  &--center{
    text-align: center;
  }

  &--upper{
    text-transform: uppercase;
  }

  &--primary{
    color: $color-primary;
  }

  &--actions{
    display: flex;
    align-items: center
  }

}


.e-headers--center {
  // text-align: center;
}
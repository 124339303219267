// Botones
@mixin input-buttons {
  padding: 0.9rem 1.5rem;
  border: 0;
  border-radius: $border-radius;
  letter-spacing: 0.5px;
  cursor: pointer;
  display: inline-block;
  transition: $trans;
  @media (max-width: 575px) {
    padding: 0.85rem 1.2rem;
  }
  @media (max-width: 390px) {
    padding: 0.8rem 1rem;
    font-size: 0.8rem;
  }

  &:hover {
    box-shadow: $box-shadow-small;
    transform: translateY(-2px);
    transition: $trans;
  }
}

@mixin input-buttons-lg {
  padding: 1.2rem 1.5rem;
  font-size: 1.1rem;
  font-weight: $font-weight-medium;
  cursor: pointer;
}

@mixin related-header {
  font-size: 1rem;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
  padding-bottom: 0.8rem !important;
  color: $color-gris;
  font-weight: $font-weight-medium;
}

@mixin tabs-title {
  font-size: 1rem;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
  padding-bottom: 0.8rem !important;
  color: $color-gris;
  font-weight: $font-weight-medium;
  @media (max-width: 767px) {
    font-size: 0.8rem;
  }
}

@mixin card-cart {
  background-color: $color-background;
  box-shadow: $box-shadow;
  padding: 1.5rem;
  border-radius: $border-radius;
  @media (max-width: 575px) {
    padding: 1rem;
  }
}

@mixin subrallado {
  color: $color-background;
  background-color: $color-gris-dark;
  border-radius: $border-radius-min;
  transition: $trans;
  padding: 5px 1rem;
  display: inline-block;
  margin-bottom: 10px;
  width: auto;
}

@mixin subrallado-h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  @media (max-width: 575px) {
    margin-bottom: 0.3rem;
    font-size: 1.2rem;
  }
}

@mixin subrallado-h3 {
  margin-top: 0;
  font-size: 1.1rem;
  @media (max-width: 575px) {
    font-size: 0.9rem;
  }
}

@mixin grid-cols($g-cols, $g-gap, $g-gap-m) {
  display: grid;
  grid-template-columns: $g-cols;
  gap: $g-gap;

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: $g-gap-m;
  }
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 400px;
  transition: $trans;
  @media screen and (max-width: 575px) {
    height: 200px;
    transition: $trans;
  }

  &__bg {
    top: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__overlay {
    top: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
    z-index: 2;
    background: rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0);
  }

  &__container {
    position: relative;
    z-index: 3;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: $trans;
  }
  &__content {
    text-align: center;
    transition: $trans;
    @media screen and (max-width: 767px) {
      align-items: center;
      transition: $trans;
    }
    @media screen and (max-width: 575px) {
      transition: $trans;
    }
  }

  &__titulo {
    color: $color-white;
    font-weight: $font-weight-bold;
    font-size: $display-3;
    transition: $trans;
    @media screen and (max-width: 1199px) {
      font-size: $header-1;
      transition: $trans;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
      transition: $trans;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
      transition: $trans;
      text-wrap: balance;
    }
  }

  &__subtitulo {
    color: $color-white;
    font-weight: $font-weight-medium;
    font-size: $header-2;
    transition: $trans;
    margin-bottom: $font-size;
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
      transition: $trans;
    }
    @media screen and (max-width: 767px) {
      font-size: $font-size;
      transition: $trans;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $font-size;
  }
}

.h-pre {
  background-color: $color-grey-6;
  @media (max-width: 991px) {
    display: none;
  }
}

.h-pre__top {
  height: 2rem;
  display: flex;
  justify-content: space-between;
  line-height: 2rem;
  font-size: 12px;

  a {
    color: $color-gris-dark;
    font-weight: $font-weight-bold;
    letter-spacing: 0.5px;
    padding: 5px;

    &:hover {
    }

    &.b-verde {
      background-color: $color-primary;
      border-radius: 50px;
      color: $color-background;
      padding: 2px 10px;
      &:hover {
        background-color: darken($color-primary, 5%);
      }
    }
  }
}

.header-checkout {
  background-color: $color-background;
  padding: $font-size 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__logo {
    a img {
      width: 200px;
      @media (min-width: 768px) and (max-width: 991px) {
        width: 150px;
      }
      @media (max-width: 575px) {
        width: 130px;
      }
    }
  }
}

.acHeader {
  @media (max-width: 575px) {
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
}

.h-header {
  background-color: $color-background;
  padding: 1rem 0;

  &__container {
    display: grid;
    grid-template-columns: max-content auto max-content;
    gap: $space-3;
    align-items: center;
    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      gap: $space-1;
    }
  }

  

  &__left {
    display: flex;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 480px) {
      gap: $font-size;
    }
  }
  
  &__burger {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 575px) {
    padding: 0.8rem 0;
  }
  &__logo {
    display: flex;
    height: 100%;
    align-items: center;
    a {
      img {
        width: 170px;
        @media (min-width: 768px) and (max-width: 991px) {
          width: 150px;
        }
        @media (max-width: 575px) {
          width: 130px;
        }
      }
    }
  }

  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &.header--sticky {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: $font-size-smaller;
    padding-bottom: $font-size-smaller;
    background-color: $color-white;
    box-shadow: $box-shadow;
    transition: $trans;
  }
  &__commerce {
    display: flex;
    gap: $space-1;
    gap: $font-size;
    align-items: center;
    @media screen and (max-width: 767px) {
      gap: calc($font-size / 2);
      margin-left: auto;
    }
  }
  &__commerce-item {
    position: relative;
    i {
      font-size: $header-3;
      color: $color-grey-1;
    }
  }

  &__commerce-item-total {
    background-color: $color-primary;
    color: $color-background;
    font-size: 11px;
    position: absolute;
    top: 0px;
    right: -5px;
    z-index: 2;
    display: inline-block;
    padding: 0px 4px;
    border-radius: 3px;
    &--oculto {
      visibility: hidden;
      transition: $trans;
    }
  }
}

.page-template-page-carrito, .page-template-page-checkout{
  .h-header__commerce-item--cart{
    visibility: hidden;
  }
}

.w-search {
  display: flex;
  justify-content: flex-end;

  &__input {
    @include input-buttons;
    max-width: 350px;
    width: 350px;
    margin-right: 5px;
    border: 1px solid $color-gris-light;
  }

  &__submit {
    background-color: $color-primary !important;
  }
}

.link-contact {
  img {
    width: 35px;
    height: auto;
  }
  &--whatssapp {
    margin-right: 1rem;
  }
}

.msearch {
  &__open {
    margin-right: 0.5rem;
    border: 0;
    background-color: #fff;
    cursor: pointer;
  }

  &__open img {
    margin-top: 3px;
    width: 25px;
    height: auto;
  }
  &__overlay {
    display: none;
    position: fixed;
    z-index: 1000;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: 0s;
    background-color: $color-primary;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
  }

  &__form {
    display: block;
    background-color: #fff;
  }

  &__content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  &__ico {
    width: 15px !important;
    height: 15px;
    margin-left: 1rem;
  }

  &__logo {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    img {
      width: 150px;
    }
  }

  &__input {
    padding: 14px 10px;
    font-size: 16px;
    border-radius: 5px !important;
    margin-right: 0px;
    border: 0px;
    border-radius: 0 !important;
    box-shadow: 0;
    outline: 0;
    width: 60%;
    margin-right: 1rem;

    &:focus {
      border-radius: 0 !important;
      box-shadow: none;
      background-color: #fff;
    }
  }

  &__close {
    background-color: $color-background;
    color: $color-gris;
    font-size: 14px;
    border: 0px;
    border-radius: 0 !important;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    padding: 11px 15px;
  }
}

.ico__cart {
  height: 35px;
  width: auto;
  color: $color-text-body;
  @media screen and (max-width: 480px) {
    height: 28px;
  }
}

// Mini cart_totals
.widget_shopping_cart {
  &_content {
    ul.product_list_widget {
      padding-left: 0;

      li.mini_cart_item {
        list-style: none;
        background-color: $color-grey-6;
        padding: 0.5rem;
        border-radius: $border-radius;
        margin-bottom: 0.5rem;
        position: relative;
        a.remove {
          position: absolute;
          right: 1rem;
          top: 1rem;
        }
        a {
          .attachment-woocommerce_thumbnail,
          .wp-post-image {
            width: 50px;
            height: auto !important;
            border: 1px solid $color-grey-5;
            margin-right: 1rem;
            border-radius: $border-radius-min;
          }
        }
        .quantity {
        }
      }
    }
    .woocommerce-mini-cart {
      &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .button {
          border-radius: 50px;
          width: 100%;
          text-align: center !important;
          justify-content: center;
          margin-bottom: calc($space-2 / 2);
          font-size: $font-size !important;
          letter-spacing: 0;
        }
        .wc-forward {
          font-weight: 400;
          background-color: $color-gris;
        }
        .checkout.wc-forward {
          background-color: $color-primary;
          padding: 0.85rem 1.875rem !important;
        }
      }
      &__total {
        border-top: 1px dashed $color-grey-4;
        padding-top: 2rem;
        margin-top: 2rem;
        font-size: $font-size-big;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        .amount {
          color: $color-primary;
        }
      }
      &__empty-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          display: flex;
          padding: 1rem 0;
        }
        img {
          width: 80px;
          height: auto;
        }
      }
    }
  }
}

.m-cart {
  display: flex;
  padding-right: 2rem;
  &__pic {
  }
  &__content {
    font-size: $font-size-small;
    .quantity {
      display: block;
      font-size: $font-size-smaller;
      color: $color-gris;
      bdi {
        color: $color-gris-dark;
      }
    }
  }
  .variation {
    color: $color-gris;
    p {
      margin-bottom: 0;
    }
    display: flex;
    flex-wrap: wrap;
    dd,
    dt {
      flex-basis: 40%;
      margin-bottom: 0;
    }
  }
}

.full-wrapper {
  overflow: hidden;
}

.woocommerce-demo-store {
  p.demo_store {
    position: fixed;
    height: auto;
    line-height: 19px;
    @media screen and (max-width: 767px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  header.h {
    @media screen and (max-width: 767px) {
      top: 62px;
    }
  }
}

.woocommerce-store-notice,
p.demo_store {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  font-size: 0.9rem;
  padding: 1em 0;
  text-align: center;
  background-color: $color-gris-dark;
  background: #6a1e95;
  color: $color-background;
  z-index: 99998;
  box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  display: none;
  @media screen and (max-width: 767px) {
    font-size: 0.8rem;
  }
  a {
    color: $color-background;
    text-decoration: underline;
  }
}

.woocommerce-store-notice__dismiss-link {
  font-weight: $font-weight-bold;
  margin-left: 10px;
}

.header-finder {
  position: relative;
  z-index: 501;
  width: 100%;
  @media screen and (min-width: 768px) {
    display: none;
  }
  &__form {
    position: relative;
    display: flex;
    width: 100%;
    .search-field {
      padding-left: $space-2;
      width: 100%;
      border-radius: 0;
      position: relative;
      font-size: $font-size;
      &--mobile {
        border-radius: 0;
        @media screen and (max-width: 480px) {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
      &::placeholder {
        color: $color-gris-dark;
      }
    }
    .search-field-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: $space-2;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      i {
        padding-left: 3px;
      }
    }
  }
}

// Sticky Header
.header {
  position: sticky;
  top: 0;
  z-index: 10;
  &.header--sticky {
    box-shadow: $box-shadow;
    transition: $trans;
    .menu-categorias {
      .ubermenu-nav {
        border-bottom: none !important;
      }
    }
    button[type="submit"] {
      background: $color-accent;
    }
  }
}

.grid-woo {
  display: grid;
  grid-template-columns: 2fr 10fr;
  gap: 3rem;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
  &__sidebar {
    display: block;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__sidebar-responsive {
    display: block;
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__content {
  }

  &--full {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.widget--woo {
  margin-bottom: 0rem;
  @media (max-width: 575px) {
    margin-bottom: 1rem;
  }
  h4.widget__title {
    display: none;
  }
  input {
    box-shadow: none !important;
  }
}

.single-product .grid-woo {
  display: block;
}

.products.columns-5 {
  margin-bottom: 2rem;
}

ul.products, div.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;

  .product {
    position: relative;
    background-color: $color-background;
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 0;
    border-radius: $border-radius;
    text-align: center;
    @media (max-width: 575px) {
      // padding: 0.5rem 0.5rem 2rem;
    }
  }

  .product__cat {
    display: none;
    margin-bottom: calc($font-size / 3);
    color: $color-gris;
    font-size: 0.75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    @media (max-width: 575px) {
      margin-bottom: 0.2rem !important;
      font-size: 0.65rem;
      letter-spacing: 0;
    }
  }

  .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    &:hover {
    }
  }

  .onsale {
    position: absolute;
    background-color: red;
    color: $color-background;
    padding: 1.5px 0.4rem 1.5px;
    font-weight: $font-weight-bold;
    border-radius: 50px;
    font-size: 0.8rem;
    right: 1rem;
    top: 1rem;
    text-transform: uppercase;
  }

  .product-out-stock {
    position: absolute;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.9rem;
    @media (max-width: 575px) {
      font-size: 0.75rem;
      margin-top: 5px;
      margin-left: 5px;
    }
  }

  .woocommerce-loop-product__link .wp-post-image {
    width: 100%;
    height: auto;
    border: 1px solid $color-gris-light;
    margin-bottom: 0.5rem;
  }

  .woocommerce-productThumbnail-container {
    background-color: $color-grey-6;
    border-radius: $border-radius;
    margin-bottom: 0.5rem;
  }

  .attachment-woocommerce_thumbnail {
    width: 100%;
    aspect-ratio: 1;
    height: auto;
    border-radius: $border-radius;
    mix-blend-mode: multiply;
  }

  .woocommerce-productTitle-container {
    padding: 0.5rem;
  }

  .woocommerce-loop-product__title {
    font-size: 0.9rem !important;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: $font-weight-bold;
    width: 100%;
    color: $color-gris-dark !important;
    text-align: left;
  }

  .add_to_cart_button,
  .product.outofstock .button.product_type_simple,
  .product.outofstock .button.product_type_variable {
    display: block;
    padding: 0.6rem 0.6rem;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    margin-top: 0.5rem;
    background-color: $color-primary;
    text-align: center;
    @media (max-width: 575px) {
      bottom: 0.5rem;
      right: 0.5rem;
      font-size: 0.85rem;
      font-weight: $font-weight-normal;
    }
  }

  .product.outofstock {
    .button.product_type_simple,
    .button.product_type_variable {
      background-color: $color-primary-lighter;
    }
  }

  .woocommerce-productPrice-container {
    padding: 0 0.5rem;
    margin-top: auto;
    margin-bottom: 0.5rem;
    text-align: left;
  }
  .price {
    margin-bottom: 1rem;
    font-size: $font-size-small;
    .woocommerce-Price-amount {
      color: $color-gris;
      font-size: $font-size-big;
      @media (max-width: 575px) {
        font-size: 1rem;
      }
    }

    ins,
    del {
      text-decoration: none;
    }
    del .woocommerce-Price-amount {
      color: $color-gris;
      font-size: 0.8rem;
      text-decoration: line-through;
    }
    ins .woocommerce-Price-amount {
      color: rgb(193, 0, 0);
      font-weight: $font-weight-bold;
      font-size: $font-size-big;
      @media (max-width: 575px) {
        font-size: 1rem;
      }
    }

    .woocommerce-Price-currencySymbol {
      font-size: calc($font-size / 1.4);
    }
  }
}

.yith-infs-loader {
  img {
    width: 48px;
    height: auto;
  }
}

.columns-6 {
  grid-template-columns: repeat(6, 1fr);
  gap: $space-3 $space-1;
  grid-auto-rows: 1fr;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
  @media (max-width: 480px) {
    grid-gap: 1rem;
  }
  @media screen and (max-width: 420px) {
    grid-template-columns: 1fr;
  }
}

.columns-5 {
  grid-template-columns: repeat(5, 1fr);
  gap: $space-3 $space-1;
  grid-auto-rows: 1fr;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $space-2 $font-size;
  }

  @media (max-width: 575px) {
    // grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 420px) {
    // grid-template-columns: 1fr;
  }
}

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  grid-auto-rows: 1fr;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
  @media (max-width: 480px) {
    grid-gap: 1rem;
  }
}

.columns-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  grid-auto-rows: 1fr;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
  @media (max-width: 480px) {
    grid-gap: 1rem;
  }
}

.last-seen-products {
  ul.product_list_widget {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: $space-3 $space-1;
    grid-auto-rows: 1fr;
    @media (min-width: 576px) and (max-width: 991px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
    }

    @media (max-width: 480px) {
      grid-gap: 1rem;
    }
    li {
      color: $color-grey-3;
      font-size: $font-size-smaller;
      margin-bottom: 0;
      a {
        background-color: $color-background;
        display: flex;
        height: 100%;
        flex-direction: column;
        padding: 0;
        border-radius: $border-radius;
        text-align: center;
        position: relative;
        &:hover {
          .product-title {
            color: $color-primary;
          }
        }
        .woocommerce-LoopProductThumbnail-container{
          background-color: #f5f5f5;
          border-radius: 7px;
          margin-bottom: .5rem;
        }
        img {
          width: 100%;
          aspect-ratio: 1;
          height: auto;
          border-radius: $border-radius;
          float: none;
          margin-right: 0;
          mix-blend-mode: multiply;
        }
        .product-title {
          font-size: $font-size-smaller;
          margin-bottom: 0.5rem;
          font-weight: $font-weight-medium;
          width: 100%;
          color: $color-gris-dark;
          text-align: left;
          @media (max-width: 575px) {
            width: 100%;
            text-align: center;
          }
        }
      }

      .product-price-container{
        display: none;
        visibility: hidden;
      }

      .woocommerce-Price-amount {
        color: $color-grey-3;
        font-size: $font-size-smaller;
      }

      ins,
      del {
        text-decoration: none;
      }
      del .woocommerce-Price-amount {
        color: $color-grey-4;
        text-decoration: line-through;
      }
      ins .woocommerce-Price-amount {
        color: rgb(193, 0, 0);
        font-weight: $font-weight-medium;
      }
      .woocommerce-Price-currencySymbol {
        font-size: calc($font-size / 1.4);
      }
    }
  }
}

.term-description {
  margin-bottom: 2rem;
  font-size: 1rem;
  @media (max-width: 767px) {
    display: none;
  }
  a {
    font-size: $font-size-small;
  }
  p,
  li {
    font-size: $font-size-small;
    color: $color-grey-3;
  }

  h2,
  h3 {
    font-size: $font-size-big;
  }
}

.woocommerce-result-count {
  display: inline-block;
  border-radius: 3px;
  font-size: $font-size-min;
  @media (max-width: 575px) {
    font-size: $font-size-min;
  }
}

.woocommerce-ordering {
  display: block;
  float: right;
  clear: both;
  margin-bottom: $space-1;
  @media (max-width: 991px) {
    float: none;
  }
}

.single-product {
  .grid-sidebar {
    grid-template-columns: 1fr;
  }
  .grid-sidebar__sidebar {
    display: none;
  }
}

/* Paginador -------------------- */
.woocommerce-pagination {
  text-align: center;

  ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid $color-primary;
    padding: 2px;
    border-radius: 3px;
  }

  li {
    display: inline-block;
  }

  li .page-numbers {
    padding: 10px 20px;
    background-color: $color-primary;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 3px;
    font-size: 1.2rem;
  }

  li .page-numbers.current {
    background-color: $color-primary;
  }
  li .page-numbers:hover {
    background-color: $color-primary;
  }
}

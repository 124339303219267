@mixin t-metodos {
  border: 3px solid rgba($color: #000000, $alpha: 0.2);
  padding: 2rem;
  gap: 2rem;
  margin-bottom: 2rem;
  border-radius: 2rem;
  @media (max-width: 767px) {
    margin-bottom: 1rem;
    padding: 1.5rem;
    gap: 1rem;
  }
}



.t-envios {
  margin-bottom: 3rem;
  &__envio {
    display: grid;
    grid-template-columns: 200px 250px 1fr;
    @include t-metodos;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__agencia {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__agencia img {
    width: 150px;
    height: auto;
  }

  &__zona {
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-gris-dark;
    background-color: rgba($color: #000000, $alpha: 0.05);
    border-radius: 1rem;
    text-align: center;
    @media (max-width: 575px) {
      text-align: left;
      display: block;
      padding: 8px;
      font-size: 0.9rem;
      border-radius: 4px;
    }
  }

  &__precio {


    span {
      font-size: 2rem;
      font-weight: $font-weight-bold;
      color: $color-gris-dark;  
    }
    div {
      font-size: $font-size-min;
    }
  }
}





.t-pagos {
  margin-bottom: 3rem;

  &__item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    @include t-metodos;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      padding: 0;
      border: 0;
      margin-bottom: 2rem;
      gap: .5rem;
    }
  }

  &__metodo {
    font-weight: $font-weight-medium;
    font-size: 2rem;
    color: $color-gris-dark;
    @media (max-width: 575px) {
      color: $color-primary;
      font-size: 1.6rem;
      margin-bottom: 0;
    }
    
  }

  &__content {}

  &__subtitulo {
    font-weight: $font-weight-medium;
    font-size: 1.1rem;
    color: $color-primary;
    text-transform: uppercase;
    @media (max-width: 575px) {
      color: $color-gris-dark;
      font-size: 1rem;
    }
  }

  &__descripcion {
    font-size: 0.9rem;
    ul,
    ol, li,
    ul li, 
    p {
      margin-bottom: 0.5rem !important;
    }
    strong {
      color: $color-gris-dark;
    }
  }
}

.mayor {
  padding: $space-3 0;
  @media screen and (max-width: 767px) {
    padding-top: $space-1; 
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: $space-3;
    @media screen and (max-width: 1199px) {
      grid-template-columns: 1fr 1.2fr;
      gap: $space-2
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: $space-2;
    @media screen and (max-width: 767px) {
      gap: $space-1;
    }
    .wp-post-image{
      width: 100%;
      height: auto;
      border-radius: $border-radius;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
  }
}

.ventajas {

  &__titulo {
    font-family: $typo-secundaria;
    font-weight: $font-weight-bold;
    font-size: $header-3;
    color: $color-gris-brown;
    font-weight: $font-weight-medium;
    margin-bottom: $space-1;
    @media (max-width: 575px) {
      font-size: 1.25rem;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__item-titulo {
    font-size: $font-size;
    font-weight: $font-weight-bold;
    color: $color-dark;
    margin-bottom: calc($font-size * 0.25);
  }

  &__item-descripcion {
    font-size: $font-size-small;
    color: $color-dark;
  }
}
.page-title {
  // background-color: rgba(64, 78, 75, 0.05);
  padding: 1rem 0 1rem;
  @media (max-width: 575px) {
    padding: 1rem 0;
  }
  @media (max-width: 390px) {
    padding: .7rem 0;
  }
  &__header {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0rem;
    margin-bottom: 0;
    font-size: 2.1rem;
    color: $color-gris-dark;
    @media (max-width: 575px) {
      font-size: 1.5rem;
    }
  }

  &__breadcrumbs {
    color: lighten($color-gris-dark, 40%);
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.8rem;
    @media (max-width: 575px) {
      font-size: .6rem;
    }
    a {
      font-weight: 300;
    }
  }
}




.home .page-title {
  display: none;
}
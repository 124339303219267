*,
::after,
::before {
  box-sizing: border-box;
}

body {
  background-color: $color-background;
  font-family: $typo-principal;
  font-size: $font-size;
  font-weight: $font-weight-normal;
  color: $color-gris;
  line-height: 1.4;
  // @media (max-width: 575px) {
  //   padding-top: 66px;
  // }
}

// a:not(.ubermenu-target) {
//   color: $color-primary;
//   text-decoration: none;
//   transition: $trans-fade;
//   &:hover {
//     color: $color-primary-dark;
//     transition: $trans-fade;
//   }
// }
a {
  color: $color-primary;
  text-decoration: none;
  transition: $trans-fade;
  &:hover {
    color: $color-primary-dark;
    transition: $trans-fade;
  }
}

strong {
  font-weight: $font-weight-bold;
}

mark {
  padding: 1px 3px;
  border-radius: 3px;
  color: #3b3e08;
  background-color: rgb(255, 217, 29);
}

.top-section {
  &.header--sticky {
    padding-top: 130px;
  }
}
.page-title.top-section {
  &.header--sticky {
    padding-top: 145px;
  }
}

body.admin-bar {
  &.home {
    .header {
      top: 32px;
    }
  }
  &:not(.home) {
    .header {
      top: 32px;
    }
  }
}

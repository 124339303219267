.g-nosotros {
  display: grid;
  @include grid-cols(1fr 1fr, 3rem, 2rem);

  &__galeria {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 0;
    list-style: none;
    margin: 0;
    gap: 1rem;
    img {
      width: 100%;
      height: auto;
    }
    li:nth-child(1) {
      grid-column: 1 / 3;
    }
  }
}
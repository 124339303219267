.p404 {
  h2 {
    font-size: 2.2rem;
    color: $color-gris-dark;
    margin-bottom: .0rem;
    font-family: $typo-secundaria;
    @media (max-width: 767px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    margin-top: 0;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-weight: 600;
    @media (max-width: 767px) {
      color: $color-secondary
;
    }
  }
}

.widget--404 {

  .product-categories {
    
    /* el primer hijo o padre */
    > .cat-item {
      margin-bottom: 2rem;
      display: block;
    }
    > .cat-item > a,
    > .cat-parent > a {
      font-weight: $font-weight-bold;
      display: inline-block;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      padding: 2px 10px;
      border-radius: $border-radius;
      @media (max-width: 767px) {
        font-size: 1.2rem;
      }
    }


    > .cat-item > a {
      background-color: $color-primary-accent;
      color: #fff;
    }
    .children {
      display: block;
      li {
        display: inline-block;
        margin-right: .8rem;
        margin-bottom: 0.4rem;
      }
    }
    .children .cat-item.cat-parent {
      display: block;
      margin-top: 1rem;
    }
    .children .cat-item.cat-parent > a {
      font-size: 1.1rem;
      font-weight: $font-weight-bold;
      margin-bottom: .5rem;
      display: block;
      color: $color-gris-dark;
    }
  }

}

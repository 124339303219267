// Grid -------------------------------

div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 2.5rem;
  grid-row-gap: 4rem;
  margin-bottom: 4rem;
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-bottom: 2rem;
  }

  .woocommerce-product-gallery {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    @media screen and (min-width: 576px) and (max-width: 699px) {
      grid-column: 1 / 5;
    }
  }

  .summary {
    grid-column: 6 / 11;
    grid-row: 1 / 2;
    @media screen and (min-width: 576px) and (max-width: 699px) {
      grid-column: 5 / 11;
    }
  }

  .woocommerce-tabs {
    grid-column: 1 / 6;
    grid-row: 2 / 3;
    @media screen and (min-width: 576px) and (max-width: 991px) {
      grid-column: 1 / 11;
    }
  }

  .related {
    grid-column: 6 / 11;
    grid-row: 2 / 3;
    @media screen and (min-width: 576px) and (max-width: 991px) {
      grid-column: 1 / 11;
      grid-row: 3 / 4;
    }
  }

  .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4;
    @media screen and (min-width: 576px) and (max-width: 991px) {
      grid-row: 4 / 5;
    }
  }
}

.summary {
  @media (max-width: 767px) {
    margin-bottom: 3rem;
  }

  .product_title {
    margin-top: 0;
    padding-top: 0;
    color: $color-gris-dark;
    font-size: $header-4;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;;

    @media (max-width: 575px) {
      font-size: 1.2rem !important;
      color: $color-gris-dark;
    }
  }

  .screen-reader-text {
    display: none;
  }

  form.cart {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: $space-2;
    gap: .5rem 0;
    @media (max-width: 340px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .qib-container {
      flex: 0 0 30%;
      @media (max-width: 991px) {
        flex: 0 0 35%;
      }
    }

    .quantity {
      flex: 0 0 30%;
      @media (max-width: 991px) {
        flex: 0 0 35%;
      }
      display: flex;
      align-items: center;
      &.hidden {
        display: none;
      }
  
      .qty {
        height: 45px;
        width: 45px;
        border-radius: 0;
        border: 1px solid $color-text-body;
        border-right: none;
        border-left: none;
      }
  
      .button {
        height: 45px;
        width: 30px;
        padding: 0;
        background-color: transparent;
        color: $color-text-body;
        border: 1px solid $color-text-body;
        display: flex;
        justify-content: center;
        &:hover {
          transform: none;
        }
        &.minus {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-right: none;
        }
        &.plus {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-left: none;
        }
      }
    }

    .single_add_to_cart_button {
      flex: 0 0 70%;
      font-size: 1rem !important;
      font-weight: $font-weight-bold;
      margin: 0 !important;
      @media (max-width: 991px) {
        flex: 0 0 65%;
        padding: 1rem 1.5rem !important;
      }

      @media (max-width: 340px) {
        margin-left: 0 !important;
        display: block;
        width: 100%;
        margin-top: 5px !important;
        font-size: 0.9rem !important;
      }
    }
  
  }
}

// Titulo y Summary -------------------------------
.product_meta {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  & > span {
    display: block;
  }
  .sku_wrapper {
    font-weight: $font-weight-bold;
    .sku {
      font-weight: $font-weight-normal;
    }
  } 
}

.summary {
  .price {
    margin: 0 0 1.5rem;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    color: $color-primary;
    @media (max-width: 575px) {
      margin-bottom: 1rem;
    }
    del,
    ins {
      text-decoration: none;
    }
    del .amount {
      font-size: 1rem;
      font-weight: $font-weight-normal;
      color: $color-gris;
      text-decoration: line-through;
    }
    ins .amount {
      font-size: 1.8rem;
      color: $color-red;
    }
  }

  .woocommerce-product-amount {
    margin: 0 0 1.5rem;
    font-weight: $font-weight-normal;
    color: $color-gris-dark;

    @media (max-width: 575px) {
      border: none;
      margin: 0 0 1rem;
      padding: 0;
    }

    @media (max-width: 390px) {
      margin: 0 0 0.5rem;
    }
  }

  .woocommerce-product-details__short-description {
    margin-bottom: 2rem;
    font-size: $font-size-small;
    @media (max-width: 575px) {
      margin-bottom: 1rem;
    }
  }
}

.single-product {
  .onsale.onsale--producto {
    display: none;
  }
}

// Agrupados Colecciones -------------------------------
.coleccion {
  margin-bottom: 2rem;

  &__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px;
  }

  &__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px;
  }

  &__quantity label {
    display: none;
  }

  &__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.5rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: $color-gris;
    min-width: 50px;
  }

  &__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: $color-gris-light;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px;
  }

  &__label label {
    display: flex;
    margin-bottom: 3px;
  }

  &__label label a {
    font-size: 13px;
    font-weight: 400;
    color: $color-gris-dark;
  }

  &__price {
    text-align: right;
    font-size: 1rem;
    color: $color-primary;
    font-weight: 700;

    .stock {
      margin: 0;
      font-size: $font-size-min;
      color: $color-gris;
    }

    .out-of-stock {
    }
  }
}

.stock.in-stock {
  color: $color-primary;
  font-size: $font-size-smaller;
  font-weight: $font-weight-medium;
  &.low-stock{
    color: $color-red;
  }
}

// VARIACIONES ------------------------
.variations_form.cart {
  display: block;
}

.variations {
  margin-bottom: 1rem;
  tr {
    display: flex;
    flex-direction: column;
  }
  .label {
    text-transform: uppercase;
    font-size: $font-size-min;
    font-weight: $font-weight-bold;
  }
  .swatchtitlelabel {
    text-transform: uppercase;
    font-size: 0.75rem;
    margin-bottom: 5px;
    display: block;
  }
  .wcva_attribute_sep {
    display: none;
  }
  .wcva-standard-select {
    margin-bottom: 1rem;
  }
  select {
    padding: 12px 40px 12px 12px;
    width: 100% !important;
  }
  tbody tr:nth-child(2) {
    margin-bottom: 1rem;
  }
  .reset_variations {
    font-size: 12px;
    color: $color-red;
    display: block;
    float: none;
  }
}

// .atawc-swatches {
//   padding: 0 !important;
//   margin-top: 5px;
//   .swatch-label {
//     background-color: #f9f9f9 !important;
//     border: 2px solid #ddd !important;
//     padding: 0.4rem 1rem !important;
//     display: inline-block !important;
//     cursor: pointer;
//     width: auto !important;
//     height: auto !important;
//     font-size: 0.85rem;
//     border-radius: 4px;
//     transition: $trans;
//     text-transform: uppercase;
//     opacity: 1 !important;
//     margin-right: 0.5rem !important;
//     @media (max-width: 575px) {
//       font-size: 0.8rem;
//       padding: 0.4rem 8px;
//     }

//     &:hover {
//       background-color: $color-primary !important;
//       color: $color-background !important;
//       border: 2px solid $color-primary !important;
//       transition: $trans;
//       padding: 0.4rem 1rem !important;
//       width: auto !important;
//       font-size: 0.85rem;
//     }
//   }
//   .swatch-label.selected {
//     background-color: $color-primary !important;
//     color: $color-background !important;
//     border: 2px solid $color-primary !important;
//     transition: $trans;
//   }
// }

// TABS -------------------------------

.woocommerce-tabs {
  @media (max-width: 767px) {
    margin-bottom: $space-2;
  }

  .wc-tab h2 {
    @media (max-width: 767px) {
      // display: none;
    }
  }
}

.entry-content {
  font-size: 1rem;
  @media (max-width: 575px) {
    font-size: 0.9rem;
  }
  .size-full,
  .size-large {
    max-width: 100%;
    height: auto;
  }
  iframe {
    max-width: 100%;
    margin: 1.5rem 0;
  }
}

.woocommerce-tabs .tabs {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  border-bottom: 0px solid $color-gris-dark;

  li {
    margin-right: 0.5rem;
    transform: translateY(1px);
    @media (max-width: 575px) {
      overflow: hidden;
      transform: translateY(0px);
      border: 0px;
    }
  }

  li a {
    background-color: rgba(0, 0, 0, 0.1);
    display: block;
    position: relative;
    padding: 0.8rem 1.5rem;
    border-radius: 3px 3px 0 0;
    color: rgba(0, 0, 0, 0.3);
    font-size: 0.9rem;
    font-weight: $font-weight-bold;
    @media (max-width: 575px) {
      padding: 0.4rem 0.8rem;
      font-size: 0.7rem;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-radius: 3px;
    }
    &:hover {
      color: $color-gris-dark;
    }
  }

  li.active {
    border-bottom: 0;
  }
  li.active a {
    color: $color-gris-dark;
    background-color: $color-background;
    position: relative;
    border-width: 1px 1px 0 1px;
    border-color: #dedede;
    border-style: solid;
    @media (max-width: 575px) {
      border-width: 1px;
    }
  }
}

.woocommerce-Tabs-panel {
  background-color: $color-background;
  padding: 2rem;
  border: 1px solid #dedede;
  border-radius: 0 4px 4px 4px;
  @media (max-width: 575px) {
    padding: $font-size;
    margin-top: .5rem;
    border-radius: 4px;
  }
  > h2,
  h2.woocommerce-Reviews-title {
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 2rem;
    color: $color-gris-dark;
    @media screen and (max-width: 575px) {
      margin-bottom: $font-size;
    }
  }
  .woocommerce-Reviews{
    .star-rating span {
      overflow: hidden;
      float: left;
    }
  }
}

.commentlist {
  list-style: none;
  padding-left: 0;
  .comment_container {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 10px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    background-color: rgba($color: #000000, $alpha: 0.02);
    border-radius: 5px;
    margin-bottom: 5px !important;
    .avatar {
      width: 40px;
      height: 40px;
      display: none;
    }
    .comment-text {
      width: 100%;
      .star-rating {
        float: none;
      }
      .meta {
        margin-top: .3rem;
        font-size: $font-size;
        margin-bottom: .3rem !important;
      }
      .description {
        p {
          margin-top: 0;
        }
      }
    }
  }
}


.comment-form-email,
.comment-form-author {
  label {
    display: block;
  }
}

.wp-comment-cookies-consent {
  label {
    font-size: $font-size-min !important;
  }
}

// Product Gallery -------------------------------
.woocommerce-product-gallery {
  margin: 0;
  position: relative;

  &__wrapper {
    margin: 0;
  }
  &__image--placeholder,
  &__image {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: $border-radius;
  }

  &__image:nth-child(n + 2) {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      mix-blend-mode: multiply;
    }
  }

  &__image:first-child {
    width: 100%;
    height: auto;
  }

  &__image--placeholder,
  &__image a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    aspect-ratio: 1;
  }

  &__image a{
    background-color: $color-grey-6;
    border-radius: $border-radius;
  }

  img.wp-post-image {
    border: 0px dotted $color-red;
    width: 100%;
    height: 100%;
    object-fit: contain;
    mix-blend-mode: multiply;
    border-radius: $border-radius;
  }

  &__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: white;
    border-radius: 50px;
    i{
      color: $color-dark;
      font-size: $header-3;
    }
    @media screen and (min-width: 576px) and (max-width: 699px) {
      top: .75rem;
      right: .75rem;
    }
    @media screen and (max-width: 480px) {
      top: 1rem;
      right: 1rem;
    }
  }
}

.woocommerce-product-gallery {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
  }
  &__image:first-child {
    grid-column: 1 / 5;
  }
}

// Relacionados -------------------------------
.cross-sells > h2,
.products.related > h2,
.products.upsells > h2 {
  @include related-header;
  margin-bottom: 1rem !important;
}

section.related ul.products {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: 1fr;
}

// Garantia -------------------------------
.garantia {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: .5rem;
      text-align: center;
      width: 100%;
    }
  }

  &__ico {
    margin-right: 0.8rem;
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
    img {
      width: auto;
      height: 40px;
      margin-top: 5px;
      @media (max-width: 575px) {
        height: 28px;
      }
    }
  }

  &__desc {
  }
}

.garantia-extras {
  padding: $font-size 0;
  font-size: $font-size-min;
  &__nav {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  &__item {
    display: flex;
    align-items: flex-start;
    gap: .5rem
  }
  a {
    color: $color-gris-dark;
    border-bottom: 1px solid;
    font-weight: $font-weight-normal;
  }
  i {
    font-size: $font-size;
  }
  a.verde-whatsapp {
    @media (max-width: 575px) {
      background-color: $color-whatsapp-light;
      display: inline-block;
      color: $color-background !important;
      border-radius: 50px;
      padding: 0 8px;
      text-decoration: none;
    }
  }
}

// Prodcut Reviews -------------------------------
.woocommerce-Reviews {
  .woocommerce-Reviews-title {
  }

  .woocommerce-noreviews {
    font-weight: $font-weight-bold;
  }
}

#review_form_wrapper {
}

#review_form {
  .comment-reply-title {
    font-size: $font-size-min;
    margin-bottom: 1rem;
    display: block;
  }

  .comment-notes {
  }
}

#commentform {
  input#author {
    width: 100% !important;
    display: block;
  }
  .comment-notes {
    display: none;
  }
  .comment-form-rating {
  }
  .comment-form-author {
  }
  .comment-form-email {
    display: none;
  }
  .comment-form-cookies-consent {
    label {
      font-size: 0.85rem !important;
    }
  }
  .form-submit {
  }
}

// star ratting -------------------------------
.product .star-rating span:before,
.star-rating::before {
  color: $color-rattings !important;
  border-color: $color-rattings !important;
}

.woocommerce-product-rating .woocommerce-review-link {
  font-size: $font-size-min;
  color: $color-gris;
}

.woocommerce-product-attributes {
  border: 1px solid $color-gris-light;
  width: 100%;
  margin-top: 1rem;

  tbody tr {
    height: auto;
  }
  tbody th {
    background-color: $color-gris-light;
    padding: 5px 10px;
    width: 30%;
  }
  tbody td {
    padding: 5px 10px;
    p {
      margin: 5px 0;
    }
  }
}

.group_table {
  margin-bottom: 2rem;
  tr.woocommerce-grouped-product-list-item {
    display: grid;
    grid-template-columns: 120px 1fr 120px;
    td:nth-child(3) {
      text-align: right;
      .in-stock {
        font-size: 0.8rem;
        margin-top: 5px;
      }
      .woocommerce-Price-amount {
        font-weight: $font-weight-bold;
        color: $color-gris-dark;
      }
    }
  }
}


.klarna-onsite-messaging-product{
  margin-bottom: 2rem;
}
@mixin headers-footer {
  font-size: $font-size-small;
  font-weight: $font-weight-medium;
  margin-bottom: $font-size;
  color: $color-dark;
  @media screen and (max-width: 575px) {
    margin-bottom: $font-size-smaller;
    font-size: $font-size;
  }
}

.footer {
  background-color: $color-background-footer;
  box-shadow: 0 50vh 0 50vh $color-background-footer;
  padding: $space-3 0 $space-2;
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__cols {
    display: grid;
    gap: $space-1;
    grid-template-columns: repeat(5, 1fr);
    padding-bottom: $space-2;
    margin-bottom: $space-2;
    border-bottom: 1px solid $color-grey-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(3, 1fr);
      gap: $space-2 $space-1;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr 1fr;
      gap: $font-size;
      gap: $space-1;
    }
  }

  &__col {
    &--contacto {
      @media screen and (min-width: 576) and (max-width: 991px) {
        grid-column: span 2;
      }
    }
  }

  &__garantias {
    padding-bottom: $space-2;
    margin-bottom: $space-2;
    border-bottom: 1px solid $color-grey-3;
    @media screen and (max-width: 767px) {
      padding-bottom: $space-1;
      margin-bottom: $space-1;
    }
  }

  &__footer {
  }
}

.garantias-footer {
  display: flex;
  gap: $space-1;
  @media screen and (min-width: 576px) and (max-width: 767px) {
    flex-wrap: wrap;
    row-gap: $space-2;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    gap: $space-1;
  }
  &__item {
    flex: 1;
    &:not(:first-child) {
      padding-left: $space-1;
      border-left: 1px solid $color-grey-3;
      @media screen and (max-width: 767px) {
        padding-left: 0;
        border-left: none;
      }
    }
  }

  &__item--pago {
    @media screen and (min-width: 576px) and (max-width: 767px) {
      flex: 0 0 100%;
    }
  }

  &__title {
    @include headers-footer();
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  &__logos {
    display: flex;
    gap: $font-size;
  }

  &__logo {
    img {
      height: 20px;
      width: auto;
    }
  }
}

.widget-footer {
  font-size: $font-size-small;
  color: $color-grey-1;
  &__title {
    @include headers-footer();
  }
  .menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
    }
  }

  .textwidget {
    p:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  a {
    font-size: $font-size-small;
    color: $color-grey-1;
    &:hover {
      color: $color-grey-3;
    }
  }
}

.footer-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-2;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  &__legal {
    font-size: $font-size-smaller;
    color: $color-text-footer;
    a {
      color: $color-text-footer;
    }
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
  &__flex {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
}

.next-gen {
  &__content {
    display: flex;
    align-items: center;
    gap: $font-size;
    flex-direction: column;
    align-items: baseline;
    @media screen and (max-width: 991px) {
      gap: $font-size;
    }
  }

  &__logos {
    display: flex;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 575px) {
      width: 100%;
      justify-content: center;
    }
  }

  &__img {
    img {
      width: 150px;
      height: auto;
      @media screen and (max-width: 991px) {
        width: 156px;
      }
    }
  }

  &__description {
    font-size: $font-size-smaller;
    color: $color-text-footer;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}

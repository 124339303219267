.boton {
  font-size: 0.9rem;
  @include input-buttons;
}

.boton--sm{
  font-size: $font-size-smaller;
  padding: 4px 12px;
  border-radius: 50px;
}

.boton--lg {
  @include input-buttons-lg;
}
.boton--block {
  text-align: center;
  display: block;
}

.boton--blanco {
  background-color: $color-background;
  color: $color-gris !important;
  &:hover {
    background-color: darken($color-background, 10%);
  }
}
.boton--principal {
  background-color: $color-primary;
  color: $color-background;
  &:hover {
    background-color: darken($color-primary, 10%);
    color: $color-background;
  }
}
.boton--secundario {
  background-color: $color-secondary
;
  color: $color-background;
  &:hover {
    background-color: darken($color-secondary
, 2%);
    color: $color-background;
  }
}

.boton--verde {
  background-color: #25d366 !important;
  color: $color-background !important;
  &:hover {
    background-color: darken(#25d366, 10%);
  }
}

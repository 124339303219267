/* SCSS Utilidades
--------------------------------------------------- */
.bg-primary {
  background-color: $color-primary;
}

.bg-secondary {
  background-color: $color-secondary;
}

.bg-accent {
  background-color: $color-primary-accent;
}

.bg-dark {
  background-color: $color-gris-brown;
  color: $color-background;
}

.bg-gris {
  background-color: rgba(64, 78, 75, 0.05);
}

.bg-gris-lighter {
  background-color: $color-gris-lighter;
}

.bg-gris-light {
  background-color: $color-gris-light;
}

.bg-h-01 {
  background-color: rgb(113, 96, 82);
}

.bg-h-02 {
  background-color: rgb(247, 243, 238);
}

.blanco {
  color: $color-background;
}

.blanco-a {
  color: $color-background;
  a {
    color: $color-background;
    text-decoration: underline;
  }
}

.verde {
  color: #25d366 !important;
}

.verde-whatsapp {
  color: #128c7e !important;
}

.fazul {
  background-color: $color-primary !important;
}

/* Alineaciones
----------------------------------------------------------------------------------- */
.text-center {
  text-align: center;
}

// .container {
//   max-width: 1240px;
//   margin: 0 auto;
//   padding-left: 20px;
//   padding-right: 20px;
// }

.acContainer {
  width: 100%;
  padding-left: $font-size;
  padding-right: $font-size;
  max-width: 1600px;
  @media screen and (min-width: 991px) {
    padding: 0 $space-2;
    // margin: 0 $font-size;
  }
  &--res-0 {
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.wooContainer {
  width: 100%;
  padding-left: $space-2;
  padding-right: $space-2;
  max-width: 1320px;
  @media screen and (max-width: 992px) {
    padding-left: $font-size;
    padding-right: $font-size;
  }
}

// Mides funcionals per a espais
// ------------------------------------------------
$sizes: (
  "top" "t" "0" $space-0,
  "top" "t" "05" $space-05,
  "top" "t" "1" $space-1,
  "top" "t" "2" $space-2,
  "top" "t" "3" $space-3,
  "top" "t" "4" $space-4,
  "top" "t" "5" $space-5,
  "bottom" "b" "0" $space-0,
  "bottom" "b" "05" $space-05,
  "bottom" "b" "1" $space-1,
  "bottom" "b" "2" $space-2,
  "bottom" "b" "3" $space-3,
  "bottom" "b" "4" $space-4,
  "bottom" "b" "5" $space-5
);

// loop top i bottom
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$d}-#{$name} {
    padding-#{$direction}: $size !important;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding-#{$direction}: calc($size / 1.5) !important;
      } @else {
        padding-#{$direction}: $size !important;
      }
    }
  }
  .mar-#{$d}-#{$name} {
    margin-#{$direction}: $size !important;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin-#{$direction}: calc($size / 1.5) !important;
      } @else {
        margin-#{$direction}: $size !important;
      }
    }
  }
}

// loop top i bottom en y
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$name} {
    padding: $size 0 !important;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding: calc($size / 1.5) 0 !important;
      } @else {
        padding: $size 0 !important;
      }
    }
  }
  .mar-#{$name} {
    margin: $size 0 !important;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin: calc($size / 1.5) 0 !important;
      } @else {
        margin: $size 0 !important;
      }
    }
  }
}

hr.separa {
  margin: calc($space-2 / 2) 0;
  border: 1px dashed $color-gris-light;
}

/* Mapa
----------------------------------------------------------------------------------- */
.map {
  padding: $font-size-min;
  background-color: #fff;
  border: 0;
}

/* Displays
----------------------------------------------------------------------------------- */

.m-no {
  @media (max-width: 575px) {
    display: none !important;
  }
}

.t-no {
  @media (min-width: 576px) and (max-width: 991px) {
    display: none !important;
  }
}

.d-no {
  @media (min-width: 992px) {
    display: none !important;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* Features
----------------------------------------------------------------------------------- */
.mask-top {
  position: relative;
  z-index: 2;
  &::before {
    content: "";
    position: absolute;
    background-color: inherit;
    height: 10px;
    left: -50%;
    mask-image: url("../assets/bg/bg-papel-02.svg");
    mask-repeat: repeat repeat-x;
    width: 150vw;
    z-index: -1;
    top: -10px;
    transform: rotate(-180deg);
  }
}

.mask-bottom {
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    background-color: inherit;
    height: 14px;
    left: -3vw;
    mask-image: url("../assets/bg/bg-papel-01.svg");
    mask-repeat: repeat no-repeat;
    width: 106vw;
    z-index: -1;
    bottom: -14px;
  }
}

.mask-bottom--02 {
  &::after {
    width: 130vw !important;
    mask-repeat: repeat repeat-x;
    left: -50%;
  }
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBoldItalic.eot');
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBoldItalic.woff2') format('woff2'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBoldItalic.woff') format('woff'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBoldItalic.ttf') format('truetype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBoldItalic.svg#BeVietnamPro-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-Italic.eot');
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-Italic.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Italic.woff2') format('woff2'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Italic.woff') format('woff'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Italic.ttf') format('truetype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Italic.svg#BeVietnamPro-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-LightItalic.eot');
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-LightItalic.woff2') format('woff2'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-LightItalic.woff') format('woff'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-LightItalic.ttf') format('truetype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-LightItalic.svg#BeVietnamPro-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-MediumItalic.eot');
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-MediumItalic.woff2') format('woff2'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-MediumItalic.woff') format('woff'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-MediumItalic.ttf') format('truetype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-MediumItalic.svg#BeVietnamPro-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-Bold.eot');
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Bold.woff2') format('woff2'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Bold.woff') format('woff'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Bold.ttf') format('truetype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Bold.svg#BeVietnamPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-Medium.eot');
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Medium.woff2') format('woff2'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Medium.woff') format('woff'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Medium.ttf') format('truetype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Medium.svg#BeVietnamPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBold.eot');
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBold.woff2') format('woff2'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBold.woff') format('woff'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBold.ttf') format('truetype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-SemiBold.svg#BeVietnamPro-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-Light.eot');
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-Light.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Light.woff2') format('woff2'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Light.woff') format('woff'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Light.ttf') format('truetype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Light.svg#BeVietnamPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-BoldItalic.eot');
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-BoldItalic.woff2') format('woff2'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-BoldItalic.woff') format('woff'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-BoldItalic.ttf') format('truetype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-BoldItalic.svg#BeVietnamPro-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-Regular.eot');
  src: url('../../dist/fonts/be-vietnam/BeVietnamPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Regular.woff2') format('woff2'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Regular.woff') format('woff'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Regular.ttf') format('truetype'),
      url('../../dist/fonts/be-vietnam/BeVietnamPro-Regular.svg#BeVietnamPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
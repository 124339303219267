@mixin woofpaddings {
  display: block;
  margin-bottom: .4rem !important;
  padding-bottom: .4rem !important;
  border-bottom: 1px dashed rgba($color: #000000, $alpha: 0.1);
}

.woof_list_checkbox {
  > .woof_childs_list_li,
  > li > li,
  > li {
    @include woofpaddings;
  }

  li  
  .woof_checkbox_label {
    display: inline-block;
    width: 70%;
  }

  .woof_checkbox_count {
    font-size: 80% !important;
  }

  .woof_childs_list {
    align-self: flex-end;
  }

}


.archive .woof_list_checkbox > li {
  @include woofpaddings;
}



// @mixin woofpaddings {
//   display: block;
//   margin-bottom: 0.4rem !important;
//   padding-bottom: 0.4rem !important;
//   border-bottom: 1px dashed rgba($color: #000000, $alpha: 0.1);
// }

// .woof_list_checkbox {
//   padding-left: 0;
//   > .woof_childs_list_li,
//   > li > li,
//   > li {
//     @include woofpaddings;
//   }

//   .woof_childs_list {
//     padding-left: 0.5rem;
//   }

//   li .woof_checkbox_label {
//     padding-top: 3px;
//     padding-bottom: 3px;
//     display: inline-block;
//     width: 70%;
//     font-family: $typo-alternative;
//   }

//   .woof_checkbox_count {
//     font-size: 80% !important;
//   }

//   .woof_childs_list {
//     align-self: flex-end;
//   }
// }

.woof_redraw_zone {
  .woof_submit_search_form_container {
    overflow: initial;

    .woof_reset_search_form {
      padding: 5px 1rem;
      border-radius: 4px !important;
      background-color: $color-grey-2;
      border: none;
      color: $color-background;
      height: auto;
      font-weight: $font-weight-medium;
    }
  }

  .irs--round .irs-from,
  .irs--round .irs-to,
  .irs--round .irs-single,
  .irs--round .irs-bar {
    background-color: $color-secondary;
  }
  .irs--round .irs-from:before,
  .irs--round .irs-to:before,
  .irs--round .irs-single:before {
    border-top-color: $color-secondary;
  }

  .irs--round .irs-handle {
    border-color: $color-secondary;
  }

  .woof_checkbox_instock_container{
    .icheckbox_square-blue{
      border: 1px solid $color-secondary;
      border-radius: 4px;
      width: 18px;
      height: 18px;
      label{
        font-size: 15px;
        color: $color-grey-1;
      }
    }
    .icheckbox_square-blue.checked{
      background-position: -51px -3px;
    }
  }
}

.woof_container {
  margin-bottom: 2rem !important;
  .woof_container_inner {
    > h4 {
      margin-bottom: 1rem;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }

  .woof_open_hidden_li {
    background-color: $color-grey-6;
    padding: 4px 0;
    font-size: $font-size-small;
    margin-top: $font-size;
  }
}

.archive .woof_list_checkbox > li {
  @include woofpaddings;
}

.woof_block_html_items {
  .chosen-single {
    height: 35px !important;
    background: none !important;
    box-sizing: content-box !important;
    span {
    }
  }
}

.woof_products_top_panel {
  line-height: initial !important;
  z-index: 1 !important;
  @media screen and (max-width: 991px) {
    margin-bottom: $space-1 !important;
  }

  .woof_products_top_panel_ul {
    display: flex;
    align-items: flex-start;
    gap: .5rem 0;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 1rem;
    }
    li {
      > a{
        background-color: $color-grey-6;
        padding: 3px 10px;
        border-radius: 50px;
        display: inline-flex;
        &:hover {
          background: $color-grey-5;
        }
        .woof_remove_ppi {
          padding-right: 0;
          position: relative;
          background: none;
          &:after {
            content: "\e9b3";
            font-family: "unicons-line";
            background: none;
            margin-left: 10px;
          }
        }
      }
      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 5px;
        margin-bottom: 5px;
        
        li {
          margin: 0 !important; 
          a {
            background-color: $color-grey-1;
            color: $color-white;
            padding: 3px 10px;
            border-radius: 50px;
            display: inline-flex;
            &:hover {
              background: $color-grey-2;
            }
            .woof_remove_ppi {
              padding-right: 0;
              position: relative;
              background: none;
              &:after {
                content: "\e9b3";
                font-family: "unicons-line";
                background: none;
                margin-left: 10px;
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }

  .woof_reset_button_2 {
    padding: 5px 1rem !important;
    border-radius: 4px;
    background-color: $color-grey-6;
    border: none;
    color: $color-grey-1;
    height: auto;
    width: max-content;
  }
}
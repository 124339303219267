.black {
  padding: $space-1 0;
  &__container {
  }

  &__banner {
    margin-bottom: $font-size;
    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  &__columns {
    display: flex;
    justify-content: space-between;
    gap: $font-size-small;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__item {
    flex: 1;
    display: inline-block;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
      transition: $trans;
      &:hover {
        transform: scale(1.05);
        transition: $trans;
      }
    }
  }
}

.home-productos {
  padding: $space-3 0 $space-1 0;
  @media screen and (max-width: 575px) {
    padding-top: $space-2;
  }
  &--last {
    margin-bottom: $space-2;
  }
}

.slider-productos .products {
  display: flex;
  align-items: flex-start;
}

.home-servicios {
  padding: $space-1 0 $space-3;
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: $space-1;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 480px) {
      gap: $font-size;
    }
  }

  &__item {
    padding: $space-1;
    background-color: $color-grey-6;
    border: 1px dashed $color-grey-4;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 575px) {
      flex-direction: row;
      justify-content: flex-start;
      gap: 2.5rem;
      padding: $font-size;
    }
  }

  &__icono {
    margin-bottom: calc($font-size * 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    img {
      width: 130px;
      height: auto;
      max-width: 130px;
      max-height: 40px;
      @media screen and (max-width: 480px) {
        max-width: 100px;
      }
    }
    @media screen and (max-width: 575px) {
      margin-bottom: 0;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 575px) {
      align-items: flex-start;
      text-align: left;
    }
  }

  &__titulo {
    font-size: $font-size;
    font-weight: $font-weight-bold;
    color: $color-dark;
    margin-bottom: calc($font-size * 0.25);
    @media screen and (max-width: 480px) {
      font-size: $font-size-small;
    }
  }

  &__descripcion {
    font-size: $font-size-small;
    color: $color-dark;
    @media screen and (max-width: 480px) {
      font-size: $font-size-smaller;
    }
  }
}

.home-categorias {
  padding: $space-1 0 $space-3;
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
}
.home-categorias-mas-vendidas {
  padding: $space-3 0;
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
}

.home-last-seen {
  padding: $space-3 0;
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  &--oculto {
    visibility: hidden;
    display: none;
  }
  &__notification {
    visibility: hidden;
    display: none;

    &--visible {
      padding: $space-3 0;

      visibility: visible;
      display: flex;
    }
  }
  &__notification-content {
    width: 100%;
    padding: $space-1;
    background-color: $color-grey-6;
    border-radius: $border-radius;
    border: 1px dashed $color-grey-4;
    color: $color-dark;
  }
}

.especial {
  margin-bottom: $space-1;
  &__container {
    max-width: 1200px;
  }

  &__link {
    display: grid;
    grid-template-columns: 3fr 1.5fr;
    border-radius: 8px;
    background-color: $color-grey-6;
    gap: 1rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__imagen {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    @media (max-width: 767px) {
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px;
    }
  }

  &__texto {
    padding: 2rem;
    @media screen and (max-width: 575px) {
      padding: 1rem;
      text-align: center;
    }
    h2 {
      font-size: 1.5rem;
      margin-top: 0;
      margin-bottom: 1rem;
      color: $color-gris-brown;
      @media screen and (max-width: 767px) {
        font-size: 1.3rem;
      }
    }
  }
}

.g-cats {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  gap: $space-1;
  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: $font-size;
  }

  &__item {
    width: 100%;
    min-height: 180px;
    border-radius: $border-radius;
    background-color: $color-hue-green-light;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: $font-size-smaller;
    @media screen and (max-width: 575px) {
      max-height: 150px;
      min-height: initial;
    }
    &:hover {
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
    &:nth-child(2) {
      background-color: $color-hue-blue-light;
      .g-cats__titulo {
        color: $color-hue-blue-dark;
      }
      .g-cats__button {
        background-color: $color-hue-blue-dark;
      }
    }
    &:nth-child(3) {
      background-color: $color-hue-orange-light;
      .g-cats__titulo {
        color: $color-hue-orange-dark;
      }
      .g-cats__button {
        background-color: $color-hue-orange-dark;
      }
    }
    &:nth-child(4) {
      background-color: $color-hue-yellow-light;
      .g-cats__titulo {
        color: $color-hue-yellow-dark;
      }
      .g-cats__button {
        background-color: $color-hue-yellow-dark;
      }
    }
  }

  &__info {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: $font-size-smaller;
    padding-right: $font-size-smaller;
  }

  &__titulo {
    font-size: $font-size;
    color: $color-hue-green-dark;
    margin-bottom: calc($font-size * 0.25);
  }

  &__descripcion {
    font-size: $font-size-smaller;
    color: $color-grey-3;
  }

  &__button {
    margin-top: auto;
    background-color: $color-hue-green-dark;
    padding: 4px 10px;
    border-radius: 50px;
    font-size: $font-size-smaller;
    color: $color-white;
  }

  &__img {
    height: 100%;
    max-width: 55%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }
}

.g-feat {
  display: flex;
  align-items: flex-start;
  &.slick-slider {
  }
  &__item {
    width: 100%;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    a {
      width: 100%;
      display: inline-block;
      padding-left: calc($font-size / 2);
      padding-right: calc($font-size / 2);
    }
  }

  &__img {
    background-color: $color-grey-6;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    aspect-ratio: 1;
    width: 100%;
    height: auto;
    position: relative;
    // max-width: 240px;
    // max-height: 240px;
    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: $border-radius;
      mix-blend-mode: multiply;
    }
    &:hover {
      .g-feat__actions {
        background-color: $color-grey-6;
        transition: background-color 0.2s;
      }
      .g-feat__button i {
        transform: rotate(90deg);
        transition: $trans;
      }
      .g-feat__text {
        visibility: visible;
        display: block;
        opacity: 1;
        transition: visibility 0.2s, display 0.2s, opacity 0.2s;
      }
    }
  }

  &__actions {
    position: absolute;
    left: $font-size-smaller;
    bottom: $font-size-smaller;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: calc($font-size * 0.5);
    padding-right: calc($font-size * 0.5);
    border-radius: 50px;
  }

  &__button {
    background-color: $color-primary;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    i {
      font-size: $font-size;
      color: $color-white;
      transition: $trans;
    }
  }

  &__text {
    visibility: hidden;
    display: none;
    opacity: 0;
    font-size: $font-size-smaller;
    color: $color-dark;
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: $font-size 0;
  }

  &__titulo {
    font-size: $font-size;
    color: $color-dark;
    margin-bottom: calc($font-size * 0.5);
    a {
      font-size: $font-size;
      color: $color-dark;
      @media screen and (max-width: 767px) {
        font-size: $font-size-small;
      }
    }
  }

  &__subcategorias {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: calc($font-size * 0.25);
  }

  &__subcategorias-item {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    text-transform: uppercase;
    color: $color-primary;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
      text-transform: none;
    }
  }
}

.gb-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
}

.gb-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 575px) {
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
  }
}

.f-marcas {
  padding: 0rem 0 1rem 0;
  margin-top: 2rem;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  display: flex;

  @media (min-width: 768px) and (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  &__item {
    @media (max-width: 767px) {
      width: 33%;
    }
  }

  img {
    max-width: 90%;
    height: auto;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
}

.descripcion-home {
  color: #a7a7a7;
  &__content h1 {
    font-size: 1.2rem;
  }
  &__content h2 {
    font-size: 1rem;
  }
  &__content p {
    font-size: 0.9rem;
    @media (max-width: 575px) {
      font-size: 0.75rem;
    }
  }
}






.contactos {
  list-style: none;
  padding-left: 3rem;
  margin-left: 3rem;
  border-left: 1px solid $color-gris-light;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-left: 1rem;
    padding-left: 1rem;
  }
  @media (max-width: 575px) {
    padding-left: 0;
    margin-left: 0;
    border: 0;
  }
  li {
    margin-bottom: 2rem;
  }
  li strong {
    text-transform: uppercase;
    display: block;
    color: $color-gris-dark;
    margin-bottom: 4px;
  }
}

.contacto{

  &__content{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4rem;
    margin-bottom: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__descripcion{
    margin-bottom: $space-2;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: $font-size;
  }

  &__item {
    display: flex;
    background-color: $color-grey-6;
    border-radius: $border-radius;
    padding: $font-size-smaller;
    gap: $font-size;
    text-decoration: none;
    color: $color-dark;
    &:hover {
      color: $color-accent;
    }
  }

  &__ico {
    width: 50px;
    aspect-ratio: 1;
    border-radius: $border-radius;
    background-color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: $header-3;
      color: $color-white;
    }
    &--whatsapp {
      background-color: $color-whatsapp-light;
      color: $color-dark;
    }
    @media screen and (max-width: 575px) {
      width: 43px;
      i {
        font-size: $font-size;
      }
    }
  }

  &__label {
    flex-direction: column;
    span {
      font-weight: $font-weight-bold;
    }
    strong {
      display: flex;
      font-weight: $font-weight-normal;
      font-size: $font-size-small;
    }
  }
  &__address {
    border: 1px dashed $color-grey-4;
    padding: $font-size;
    border-radius: $border-radius;
  }

  &__form{

  }
}

.contacto-mapa{
  background-color: $color-background-footer;
}
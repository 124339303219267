.grid-post {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-row-gap: 3rem;
  }

  &__item {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    background-color: $color-background;
  }

  &__item {
    display: block;
  }
  &__item a img {
    border-radius: 0.5rem 0.5rem 0 0;
    height: 230px;
    width: 100%;
    object-fit: cover;
  }
  &__content {
    padding: 1.5rem;
  }

  &__taxonomy {
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    margin-bottom: 0.5rem;
  }

  &__title {
    line-height: 1.5rem !important;
    margin-top: 0;
  }

  &__title a {
    color: var(--color-texto);
    font-size: 1.4rem;
  }

  &__time {
    font-size: 0.7rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.3);
    font-weight: $font-weight-medium;
  }

  &__nav {
    // grid-column: 1 / 4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.post {
  &__title {
    font-size: 3rem;
    font-weight: $font-weight-medium;
    color: $color-gris-bg-dark;
    margin-bottom: 1rem;
    margin-top: 0;
    @media (max-width: 575px) {
      font-size: 2rem;
      margin-top: 1rem;
    }
  }

  &__meta {
    text-transform: uppercase;
    margin-bottom: 3rem;
    @media (max-width: 575px) {
      font-size: $font-size-min;
    }
    a {
      font-weight: $font-weight-medium;
    }
    span {
      color: rgba(0, 0, 0, 0.5);
      font-weight: $font-weight-medium;
      margin-left: 1rem;
    }
  }
}

.page__entry,
article.post .post__entry {
  h2 {
    font-weight: $font-weight-bold;
    color: $color-primary;
    font-size: $header-2;
  }

  h3 {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    color: $color-primary-dark;
  }
  h4 {
    font-size: $header-4;
    font-weight: $font-weight-medium;
    color: $color-primary-dark;
  }

  .wp-block-heading{
    &:nth-child(1){
      margin-top: 0;
    }
  }

  ol,
  ul,
  p {
    margin-bottom: 2rem !important;
  }

  ol,
  ul,
  p {
    line-height: 1.7;
    font-size: $font-size;
    @media (max-width: 575px) {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }

  p strong {
    line-height: 2rem;
    font-size: 1.1rem;
  }

  h2,
  h3 {
    margin-top: 2.5rem;
    margin-bottom: 1.2rem;
  }

  img.size-full {
    max-width: 100% !important;
    height: auto;
  }

  img.size-large {
    max-width: 100% !important;
    height: auto;
  }

  figure.wp-block-image {
    display: block;
    margin: 2rem 0 !important;
  }

  figure.wp-block-image img {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    height: auto;
  }

  figure.wp-block-image figcaption {
    text-align: center;
    display: block;
    padding-bottom: 5px;
    font-size: 0.7rem;
  }
}

// article.page .entry {
//   margin-bottom: 3rem;

//   .wp-block-image {
//     margin: 1.2rem 0;
//     text-align: center;
//   }
//   .wp-block-quote {
//     margin: 2rem 0 2rem 0rem !important;
//     padding-left: 2rem;
//     border-left: 2px solid $color-primary;
//     font-size: 1.2rem !important;
//   }
//   p {
//     margin-top: 0;
//   }
//   ul {
//     margin-bottom: 1.2rem;
//     li {
//       margin-bottom: 0.3rem;
//     }
//   }
//   h2 {
//     color: $color-primary;
//     font-size: 1.9rem;
//   }
//   h3 {
//     color: $color-gris-dark;
//     font-size: 1.1rem;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//   }
//   strong {
//     color: $color-gris;
//   }
// }

.acf-entry--min {
  font-size: $font-size-min;
  ul {
    padding-left: 1rem;
  }
  ul li {
    margin-bottom: 5px;
  }
}

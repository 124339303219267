.ubicacionFull {
  .cmplz-placeholder-1{
    height: inherit;
  }
  iframe {
  }
}

.imagen-destacada {
  padding-top: $space-4;
  &__thumb {
    img {
      width: 100%;
      height: auto;
      border-radius: $border-radius-min;
    }
  }
}

.informacion {
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding: $space-2 0;
  }
  &__content {
  }
}

.galeriaImagenes {

  &__items {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: calc($font-size / 2);
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 420px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    img {
      width: 100%;
      height: auto;
      border-radius: $border-radius-min;
      aspect-ratio: 4/3;
      object-fit: cover;
    }
  }
}
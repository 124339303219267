#submit_bizum_payment_form,
#submit_redsys_payment_form {
  font-size: 0.9rem;
  background-color: $color-primary;
  color: $color-background;
  margin-right: 1rem;
  box-shadow: none;
  width: auto !important;
  @include input-buttons;
  display: inline-flex !important;
}

.button.cancel {
  background-color: $color-gris-light;
  color: $color-gris;
}

.product_type_simple,
.submit,
.button {
  font-size: 0.9rem;
  background-color: $color-primary;
  color: $color-background;
  display: inline;
  box-shadow: none;
  @include input-buttons;
  &:hover {
    background-color: darken($color-primary, 10%);
    color: $color-white;
  }
}

.single_add_to_cart_button {
  background-color: $color-primary;
  padding: 1.1rem 2.75rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: auto;
  font-size: 1.1rem !important;
  line-height: 1;
  letter-spacing: 0;
  gap: .5rem;
  @media (max-width: 575px) {
    font-size: 1rem;
    padding: 1.3rem 1.5rem !important;
  }
  &::before {
    font-family: "WooCommerce";
    content: "\e01d";
    font-weight: 300;
    font-size: 1.3rem;
    @media (max-width: 575px) {
      font-size: 1rem;
    }
  }
  &:hover {
    background-color: darken($color-primary, 10%);
    color: $color-white;
  }
}

.woocommerce-variation-add-to-cart {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important;
}

.checkout-button,
.single_add_to_cart_button {
  background-color: $color-primary;

  &:hover {
    background-color: darken($color-primary, 5%);
    color: $color-background;
  }
}

.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit:disabled[disabled],
.woocommerce a.button.disabled,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  &:hover {
    transition: none !important;
    box-shadow: 0 0 0;
    transform: none;
    background: $color-primary;
  }
}

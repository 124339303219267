.page-id-9 .woocommerce:not(.off-carrito) {
  display: grid;
  grid-template-columns: 3.3fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0;

  @media (max-width: 991px) {
    display: block;
  }

  .woocommerce-notices-wrapper {
    grid-column: 1 / 3;
  }
  .cross-sells {
    grid-column: 1 / 3;
  }
}

.shop_table {
  width: 100%;
}

/* Tabla del carrito de la compra CELDAS INDIVIDUALES */
.woocommerce-cart-form {
  margin-bottom: 2rem;

  .shop_table {
    width: 100%;
  }

  .product-remove {
    width: 40px;
    font-size: 1.2rem;
    text-align: center;

    &::before {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .product-remove a {
    font-size: 1.5rem;
    color: rgba(129, 13, 13, 0.774) !important;
  }

  .product-thumbnail {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .product-thumbnail a {
    display: flex;
  }

  .product-thumbnail img {
    width: 80px;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  .product-name {
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: $font-weight-normal;
    line-height: 1rem !important;
    a {
      color: $color-gris !important;
    }
  }

  .product-price {
    text-align: right;
  }

  .product-quantity {
    text-align: right;

    .qty {
      padding: 9px 2px;
    }
  }

  .product-subtotal {
    font-weight: $font-weight-bold;
    text-align: right;

    .tax_label {
      font-size: 11px !important;
      font-weight: $font-weight-normal;
      display: block;
    }
  }

  thead {
    tr th {
      height: 3rem;
      line-height: 3rem;
      font-size: 0.8rem !important;
      text-transform: uppercase;
    }
  }
}

/* Tabla del carrito de la compra -------------------------- */
.woocommerce-cart-form table.shop_table {
  @include card-cart;
  border-collapse: initial;
  thead {
    @media (max-width: 767px) {
      display: none;
    }
  }

  tbody {
    tr {
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
    }

    td {
      @media (max-width: 767px) {
        display: block;
        width: 100%;
        text-align: right;
        font-size: 0.9rem;
      }
    }

    td.product-thumbnail {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .cart_item {
    @media (max-width: 767px) {
      border: 0px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 5px 5px 4px 5px;
      margin-bottom: 10px;
    }
  }

  .cart_item td {
    border-bottom: 2px solid $color-background;
    background-color: rgba(0, 0, 0, 0.03);
  }

  td {
    padding: 5px 9px;
    vertical-align: middle;
    line-height: 1.5em;
  }

  td a {
    color: $color-primary-dark;
  }

  th {
    font-weight: 700;
    padding: 7px 9px;
    line-height: 1.5em;
  }

  .actions {
    padding: 1rem 0 0 0;

    &::before {
      @media (max-width: 575px) {
        display: none;
      }
    }

    .coupon .button {
      background-color: $color-gris-light !important;
      color: $color-gris !important;
    }
  }
}

.coupon {
  label {
    display: none;
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
  }

  input {
    @media (max-width: 767px) {
      margin-right: 10px;
      width: 50%;
    }
  }
}

/* Atributos en tabla para responsive --------------- */
.woocommerce table.shop_table_responsive tr td::before,
.woocommerce-page table.shop_table_responsive tr td::before {
  @media (max-width: 767px) {
    content: attr(data-title) ": ";
    font-weight: 400;
    float: left;
  }
}

.cart_totals table.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px;
}

.woocommerce table.shop_table .screen-reader-text {
  display: none;
}

.update_cart {
  float: right;
}

.cart-collaterals {
  width: 100%;
  display: flex;
  justify-content: right;
}

/* CARRITO TOTALES Y TRANSPORTE ------------------- */

.cart_totals {
  display: block;
  width: 100%;
  @include card-cart;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 0.9rem !important;
  }

  h2 {
    font-size: 1.5rem !important;
  }

  .shop_table {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
    border-radius: 3px;
  }

  tbody tr td,
  tbody tr th {
    padding: 12px 12px !important;
  }

  tbody tr td {
    border: 1px solid rgba(0, 0, 0, 0.05);

    &::before {
      display: none;
    }
  }

  tbody tr th {
    font-size: $font-size-min;
    text-align: right;
    width: 25%;

    @media (max-width: 767px) {
      width: 25%;
    }
  }

  tbody tr th:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  h2 {
    margin-top: 0;
  }

  .woocommerce-shipping-destination {
    font-weight: $font-weight-normal !important;
  }

  .checkout-button {
    display: block;
    font-size: 1.4rem;
    text-align: center;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
    font-weight: $font-weight-bold;
  }
}

.woocommerce-shipping-methods {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 5px !important;
  input.shipping_method {
    width: auto;
    display: inline-block;
    height: 20px !important;
  }
  li {
    margin-bottom: 1rem !important;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4px 8px;
    &:nth-last-child(1){
      margin-bottom: 0 !important;
    }

    input.shipping_method {
      flex: 0 0 5%;
    }

    label {
      flex: 0 0 86%;
      font-weight: $font-weight-bold;
      display: inline-block;
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        font-size: $font-size-small;
      }
      @media screen and (max-width: 575px) {
        font-size: $font-size-smaller;
      }
      b {
        font-weight: $font-weight-normal;
      }
    }

    label span {
      font-weight: $font-weight-normal;
    }
  }
  .betrs_option_desc {
    flex: 0 0 100%;
    padding-left: 22px;
    font-size: $font-size-min;
    font-weight: $font-weight-normal !important;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
    b {
      font-size: $font-size-min;
      font-weight: $font-weight-normal !important;
    }
  }
}

.woocommerce #content table.cart td.actions,
.woocommerce table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
  text-align: right;
}

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
  float: left;
}

// @media (max-width: 767px) {
//   .woocommerce table.shop_table_responsive tr td::before,
//   .woocommerce-page table.shop_table_responsive tr td::before {

//     content: attr(data-title) ": ";
//     font-weight: 700;
//     float: left;
//   }
// }

.hook-cart {
  &--transporte {
    font-size: $font-size-small;
    margin-top: $space-1;
  }
}

.widget {
  // margin-bottom: 2rem;
  &__title {
    font-size: $font-size;
    font-weight: $font-weight-medium;
    margin-bottom: $space-1;
    color: $color-text-footer;
    @media (max-width: 767px) {
      margin-bottom: $font-size;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
    }
  }

  .textwidget {
    p:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}

.widget--header {
  margin-bottom: 0;
}

.widget-legal{
  &__title{
    font-size: $header-3;
    font-weight: $font-weight-medium;
    margin-bottom: $space-1;
    color: $color-grey-2;
  }
}

// Widgets para Woocommerce

.fb_iframe_widget {
  border: 1px solid var(--color-gris-light);
}

.widget--woo {
  margin-bottom: 4rem;
  .widget__title {
    font-size: 0.85rem;
    letter-spacing: 0;
  }
}

.product_list_widget {
  li {
    display: block;
    margin-bottom: 1.5rem;
    overflow: hidden;
  }

  .size-woocommerce_thumbnail {
    width: 70px;
    height: auto;
    float: left;
    margin-right: 1rem;
  }
  li a {
    color: $color-primary;
    font-weight: $font-weight-medium;
    font-size: 0.9rem;
  }
  li span {
    font-size: 0.9rem;
  }
}

/* Widget menu para sidebar ---------------------- */
.widget--woo .product-categories {
  margin-top: 0;

  .count {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .cat-item,
  .cat-parent {
    margin-bottom: 5px;
  }

  .cat-item {
  }

  > .cat-item > a {
    font-size: 1.1rem;
    font-weight: $font-weight-medium;
    color: $color-accent;
  }

  > .cat-item {
  }

  .cat-parent > a {
    margin-bottom: 5px;
    display: inline;
  }

  .children {
    padding-left: 1.5rem;
    @media (max-width: 1100px) {
      padding-left: 0;
    }
  }

  .children .cat-item {
  }

  .children .cat-item a {
    text-transform: uppercase;
    font-size: 0.9rem;
    color: $color-primary;
  }
}

/* Widget menu para capa overlay ---------------------- */
.widget--woo-m .product-categories {
  text-align: center;
  margin-top: 0;
  margin-bottom: 3rem;

  .cat-item,
  .cat-parent {
    margin-bottom: 8px;
  }

  .cat-item {
  }

  > .cat-item > a {
    font-size: 1.2rem;
    font-weight: $font-weight-medium;
    color: $color-gris;
    background-color: rgba(255, 255, 255, 0.9);
    display: inline-block;
    border-radius: 50px;
    padding: 0.7rem 1.5rem 0.7rem;
  }

  > .cat-item {
  }

  .cat-parent > a {
    margin-bottom: 8px;
    display: block;
    display: inline-block;
  }

  .children {
    padding-left: 0rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .children .cat-item {
    margin-right: 4px;
  }

  .children .cat-item a {
    font-size: 1rem;
    // color: $color-gris;
    // background-color: rgba(255, 255, 255, 0.8);
    background-color: initial;
    border: 2px solid rgba(255, 255, 255, 0.8);
    display: inline-block;
    border-radius: 50px;
    padding: 0.5rem 1.2rem 0.6rem;
  }
}

.woof_container {
  margin-bottom: 1.5rem !important;
}

.woof_container_inner {
  h4 {
    color: $color-gris-bg-dark;
  }
}

.woof_list {
  > li {
    label span,
    label {
      font-size: 0.9rem !important;
    }
  }
  .woof_childs_list {
    font-weight: $font-weight-normal !important;
  }
}

.woof_submit_search_form_container {
  .woof_reset_search_form {
    background-color: $color-gris-light;
    color: $color-gris;
  }
}

.woof_products_top_panel {
  ul li a {
    background-color: #fff;
    padding: 5px 0.8rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 0.9rem;
    color: $color-gris;
  }
}

.g-cols {
  @include grid-cols(1fr 1fr, 3rem, 2rem);

  &__form {}

  &__contacto {}
}




.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3rem;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  &__item {}
}


.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
    }
}


.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  &__item {}
}


.grid-4--footer {
  grid-template-columns: 2fr 1fr 1fr 1fr;
  @media (max-width: 575px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
  }

  .grid-4__item:nth-child(1) {
    @media (max-width: 575px) {
      grid-column: 1 / 3;
    }
  }
  .grid-4__item:nth-child(4) {
    @media (max-width: 575px) {
      grid-column: 1 / 3;
    }
  }
}





.grid-sidebar {
  display: grid;
  grid-template-columns: 2fr 9fr;
  grid-gap: 3rem;
  margin-bottom: $space-2;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-gap: 2rem;
    grid-template-columns: 4fr 8fr;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin-bottom: 0;
  }
}



.g-blog {
  display: grid;
  grid-template-columns: 9fr 3fr;
  grid-gap: 3rem;
  margin-bottom: $space-2;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-gap: 2rem;
    grid-template-columns: 9fr 3fr;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin-bottom: 0;
  }
}
.woocommerce-product-search {
  width: 100%;
  padding: 5px 8px 5px 1rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  background-color: $color-grey-6;
  background: #e9f4f2;

  label {
    display: none;
  }

  .search-field {
    border: none;
    padding-left: 0;
    color: $color-grey-3;
    width: 100%;
    background: none;
  }

  button[type="submit"] {
    @include input-buttons;
    padding: 0.4rem 1rem;
    background-color: $color-primary;
    color: $color-background;
    border-radius: 50px;
  }
}

.search-overlay {
  display: none;
}

.search-overlay-m {
  z-index: 5;
  width: 100%;
  left: 0;
  right: 0;
  padding: 5px 10px;
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-top: 16px;
  @media (min-width: 576px) {
    display: none;
  }
  .woocommerce-product-search {
    border: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 5px;
    button {
      border-radius: 5px;
      background-color: $color-secondary;
      color: #fff;
    }
    input {
      font-size: 16px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}

.slick-slider{
  display: block !important;
}
.slider-productos {
  .products {
    display: block;
    overflow: hidden;
  }

  .slick-track {
    display: flex !important;
  }

  .product {
    margin-right: calc($space-1 * 0.5);
    margin-left: calc($space-1 * 0.5);
    height: inherit !important;
    @media screen and (max-width: 767px) {
      margin-right: calc($space-1 / 3);
      margin-left: calc($space-1 / 3);
    }
  }

  .slick-arrow {
    &:before {
      font-family: unicons-line;
      font-style: normal;
      font-weight: 400;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 2rem;
      color: $color-grey-3;
    }
    &:hover {
      &:before {
        color: $color-grey-2;
      }
    }
  }

  .slick-prev,
  .slick-next {
    top: 30%;
  }

  .slick-prev {
    &:before {
      content: "\eb79";
    }
  }

  .slick-next {
    &:before {
      content: "\eb6d";
    }
  }
}

.slick-dots {
  bottom: -2.5rem !important;
  li {
    width: 14px !important;
    height: 14px !important;
    @media screen and (max-width: 767px) {
      margin: 0 !important;
    }
    button {
      &:before {
        font-size: 1.5rem !important;
        color: $color-grey-5;
        opacity: 1;
      }
    }
    button:hover::before {
      color: $color-grey-3;
      opacity: 1;
    }

    &.slick-active {
      button::before {
        color: $color-grey-2;
        opacity: 1;
      }
    }
  }
}

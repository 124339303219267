.login {
  position: relative;
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
    @media screen and (max-width: 767px) {
      background: rgba($color: #000000, $alpha: 0.7);
    }
  }
  &__container {
    position: relative;
  }
}

.page-template-page-login.logged-out .bg-login {
  background: url("../assets/site/abonos-calsilla-bg-login.jpg") center center;
  background-size: cover;
}

.page-template-page-login.logged-in .login__overlay {
  background: none;
}

.logged-out.page-template-page-login .woocommerce > h2 {
  display: none;
}

.woocommerce-form-login {
  @include card-cart;
  max-width: 400px;
  margin: 0 auto;
}

.woocommerce-form-register {
  @include card-cart;
}

.woocommerce-form-row,
.woocommerce-form-login {
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 0.9rem;
  }

  input {
    display: block;
    width: 100%;
  }

  &__rememberme {
    padding-bottom: 1rem !important;
  }

  &__submit {
    display: block;
    width: 100%;
  }

  .lost_password {
    font-size: $font-size-min;
    text-align: right;
  }
}


.page-template-page-login.logged-in .woocommerce:not(.off-carrito) {
  display: grid;
  grid-template-columns: 3fr 9fr 1fr;
  grid-gap: 3rem;

  @media (max-width: 991px) {
    display: initial;
  }
}

.woocommerce-MyAccount-navigation {
  @media screen and (max-width: 991px) {
    margin-bottom: $space-2;
  }
  ul {
    @include card-cart;
    list-style: none;
    margin-bottom: 0;

    @media (max-width: 991px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: $font-size;
      padding: 0;
      background-color: initial;
      margin-top: 0;
      box-shadow: none;
    }
  }

  li {
    &:hover a{
    @media screen and (max-width: 991px) {
      color: $color-background;
      background-color: $color-accent;
    }
  }
    a {
      @media (max-width: 991px) {
        padding: 5px 1rem;
        font-size: $font-size-min;
        background-color: $color-gris-light;
        color: $color-gris;
        border-radius: 50px;
      }
    }
  }
  li.is-active a {
    color: $color-gris-dark;
    @media (max-width: 991px) {
      font-size: $font-size-min;
      color: $color-background;
      background-color: $color-accent;
    }
  }
}

.woocommerce-MyAccount-content {
  .woocommerce-table--order-details {
    display: block;
  }
  .woocommerce-Message .button {
    display: inline-block;
  }
}


.woocommerce-EditAccountForm {
  .woocommerce-form-row input {
    width: 100%;
  }

  span em {
    font-size: $font-size-min;
  }
}

.account-orders-table {
  font-size: .9rem;
  border-collapse: collapse;
  thead {
    @media (max-width: 767px) {
      display: none;
    }
  }
  tbody {
    @media (max-width: 767px) {
      font-size: $font-size-min;
    }
    tr {
      @media (max-width: 767px) {
        display: block;
        display: flex;
        flex-direction: column;
        background-color: $color-background;
        margin-bottom: 1rem;
        padding: 1rem;
        border-radius: $border-radius;
        
      }
    }
    td {
      // text-align: center;
      padding: 10px 5px 10px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 0;
      border-right: 0;
      @media (max-width: 767px) {
        text-align: right;
        padding: 8px 5px;
      }
    }

    .woocommerce-orders-table__cell-order-actions{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      a.button{
        flex: 1;
        text-align: center;
        padding: 8px 14px;
        font-size: $font-size-small;
        &.cancel{
          &:hover{
            background-color: #dedede;
            color: #747474;
          }
        }
      }
    }

    .view {
      // padding: 6px 1rem;
      // display: block;
    }
  }

  .woocommerce-orders-table__cell-order-status {
    font-weight: $font-weight-bold;
  }
  // .woocommerce-orders-table__cell-order-actions .view {
  //   display: inline-block;
  // }
}




div[data-title="Cancelado"] {
  color: rgb(118, 14, 14);
}





#customer_login {
  h2{
    color: $color-white;
  }
  .woocommerce-form-row > label {
    font-weight: $font-weight-bold;
  }
  .woocommerce-form-login {
    margin-left: 0;
  }

}



.woocommerce-privacy-policy-text {
  font-size: $font-size-min;
}
.tablaCaracteristicas {
  padding: $font-size;
  background-color: $color-hue-yellow-light;
  border: 1px dashed $color-hue-yellow-dark;
  border-radius: $border-radius;
  margin-bottom: $space-1;

  &__content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $font-size;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: $font-size-small;
    }
  }

  &__item {
    display: flex;
    gap: calc($font-size / 2);
    &:last-child {
      grid-column: span 2;
    }
  }

  &__item-icono {
    img {
    }
  }

  &__item-info {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
  }

  &__item-titulo {
    font-weight: $font-weight-medium;
    font-size: $font-size-smaller;
  }

  &__item-descripcion {
    font-size: $font-size-smaller;
  }
}

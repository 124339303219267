.wpcf7 {
  margin: 0rem 0 2rem;
  padding: $space-1;
  background: $color-grey-6;
  border-radius: $border-radius;
  @media screen and (max-width: 767px) {
    padding: $font-size;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: 500;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    // text-transform: uppercase;
    margin-bottom: 0.2rem !important;
  }

  .form-control {
    width: 100%;
    padding: 0.575rem 0.75rem;
    font-size: $font-size-min;
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }

  .wpcf7-select{
    font-size: $font-size-min;
  }
}

.gdpr-text {
  margin-top: $font-size;
  margin-bottom: $font-size;
  font-size: 0.6rem;
  color: $color-grey-3;
  color: rgba(0, 0, 0, 0.5);
}

.wpcf7-acceptance {
  .wpcf7-list-item {
    margin-left: 0;
    font-size: $font-size-min;
    a {
      color: $color-grey-1;
      text-decoration: underline;
      font-weight: $font-weight-medium;
    }
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

input[type="file"] {
  // color: transparent;
  font-size: 12px;
}

// Mostrar el badge recapcha solo en la pagina de contacto
body:not(.page-template-page-contacto) {
  .grecaptcha-badge {
    visibility: hidden;
  }
}



/* buscador superior ------------ */
div[id*="ajaxsearchlite"].wpdreams_asl_container {
  width: 80% !important;
}

#ajaxsearchlite1 .probox,
div.asl_w .probox {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  padding: 12px !important;
}


div.asl_r.vertical {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3) !important;
}
.columns-3,
.columns-4,
.columns-5,
.columns-6 {
  .woocommerce-info {
    font-size: $font-size-min;
  }
}

.woocommerce-notices-wrapper {
  display: block;
  padding-top: $font-size;
  padding-bottom: $space-2;

  &:empty {
    padding: 0;
  }
  .woocommerce-error {
    li {
      color: $color-red;
    }
  }
  .woocommerce-message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $space-2;
    padding: $font-size;
    background-color: $color-background;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    font-size: $font-size-big;
    text-align: left;
    font-weight: $font-weight-bold;
    background-color: $color-hue-yellow-light;
    border: 1px solid $color-hue-yellow-dark;
    @media screen and (max-width: 991px) {
      font-size: $font-size;
    }
    @media (max-width: 575px) {
      gap: $space-1;
      font-size: $font-size-small;
    }
    @media screen and (max-width: 420px) {
      font-size: $font-size-smaller;
      padding: .75rem;
    }
    a.restore-item {
      @media (max-width: 575px) {
        height: auto;
        display: inline;
      }
    }
  }

  .button {
    @include input-buttons;
    font-weight: $font-weight-medium;
    padding: 8px 20px;
    font-size: $font-size-small;
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;
    text-align: center;
    white-space: nowrap;
    &:hover {
      background-color: darken($color-primary, 10%);
      border-color: darken($color-primary, 10%);
      color: $color-background;
    }
    @media (max-width: 575px) {
      font-size: $font-size-smaller;
      padding: 8px 16px;
    }
  }
}


.woocommerce-NoticeGroup-checkout .woocommerce-error {
  li {
    color: rgb(136, 15, 15);
  }
}

.product-out-stock,
.summary
.out-of-stock {
  background-color: $color-red;
  color: $color-background;
  display: inline-block;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: $font-size-smaller;
}
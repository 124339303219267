.paginasLegales {
  padding: $space-1 0 $space-3 0;
  &__row {
    display: grid;
    grid-template-columns: 2fr 9fr;
    grid-gap: $space-3;
    @media screen and (max-width: 1199px) {
      gap: $space-2;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-3;
    }
  }

  &__col {
  }

  &__col--side{
  }

  &__sidebar {
  }

  &__content {
  }
}